import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import ExporAbsenManual from "./export/ExportAbsenManual.js";
import ExportCuti from "./export/ExportCuti";
import ExportIzin from "./export/ExportIzin";
import ExportLaporanBulanan from "./export/ExportLaporanBulanan.js";
import ExportLaporanPeriodik from "./export/ExportLaporanPeriodik.js";
import ExportPenilaianSKA from "./export/ExportPenilaianSKA.js";
import ExportSka from "./export/ExportSka.js";
import { AddSubsidiary, Approval, CostProfit, Dashboard, DataImport, DetailBlog1, DetailBlog2, DetailBlog3, DetailSubsidiary, Document, EditSubsidiary, EmployeeList, EmployeeTransfer, GeneralInformation, Inbox, InboxDetail, JobBulkUpload, JobManagement, Login, MainBlog, Nationalities, News, NewsCreate, NewsEdit, NewsView, Register, Structure, Subsidiary, Template, UserManagement } from "./pages";
import Aboutus from "./pages/Aboutus";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import ErrorPage from "./pages/Errors/index.js";
import Device from "./pages/Main/Configuration/Device/index.js";
import EmployeeConfiguration from "./pages/Main/Configuration/EmployeeConfiguration";
import HakAkses from "./pages/Main/Configuration/HakAkses/index.js";
import Helpdesk from "./pages/Main/Configuration/Helpdesk/index.js";
import PayrollSetting from "./pages/Main/Configuration/PayrollSetting";
import Pelaporan from "./pages/Main/Configuration/Pelaporan/index.js";
import PengaturanKehadiran from "./pages/Main/Configuration/PengaturanKehadiran/index.js";
import IndikatorKuantitatif from "./pages/Main/Configuration/Performance/IndikatorKuantitatif.js";
import PerformanceSetting from "./pages/Main/Configuration/Performance/index.js";
import Kinerja from "./pages/Main/Configuration/Performance/kinerja.js";
import TugasPokok from "./pages/Main/Configuration/Performance/tugasPokok.js";
import TugasStrukturalAsisten from "./pages/Main/Configuration/Performance/tugasStrukturalAsisten.js";
import TugasTambahan from "./pages/Main/Configuration/Performance/tugasTambahan.js";
import PreviewPerformanceSetting from "./pages/Main/Configuration/PerformanceSetting/PreviewPerformance";
import Punishment from "./pages/Main/Configuration/Punishment";
import TimeManagementSetting from "./pages/Main/Configuration/TimeManagementSetting";
import OfficialWorkTravelSetting from "./pages/Main/Configuration/TimeManagementSetting/OfficialWorkTravelSetting";
import PengaturanHariLibur from "./pages/Main/Configuration/TimeManagementSetting/PengaturanHariLibur/index.js";
import CreatePolling from "./pages/Main/Dashboard/CreatePolling";
import HistoryAnnouncement from "./pages/Main/Dashboard/HistoryAnnouncement";
import Polling from "./pages/Main/Dashboard/Polling";
import DetailAnswerPolling from "./pages/Main/Dashboard/Polling/DetailAnswerPolling";
import DetailPolling from "./pages/Main/Dashboard/Polling/DetailPolling";
import PollingForm from "./pages/Main/Dashboard/Polling/PollingForm";
import Answer from "./pages/Main/Document/Answer";
import OpenDocument from "./pages/Main/Document/Open Document";
import StatisticAnswer from "./pages/Main/Document/Statistic Answer";
import AddEmployee from "./pages/Main/Employee/AddEmployee";
import DetailEmployee from "./pages/Main/Employee/DetailEmployee";
import MassResign from "./pages/Main/Employee/MassResign";
import CashAdvance from "./pages/Main/Finance/Cash Advance";
import DetailLoan from "./pages/Main/Finance/Detail Loan";
import Finance from "./pages/Main/Finance/Finance";
import FinanceSetting from "./pages/Main/Finance/Finance Setting";
import Loan from "./pages/Main/Finance/Loan";
import Reimbursement from "./pages/Main/Finance/Reimbursement";
import DetailLaporanSayaBulanan from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/LaporanKinerjaSaya/Detail/Bulanan/index.js";
import DetailLaporanSayaPeriode from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/LaporanKinerjaSaya/Detail/Periode/index.js";
import EditRealisasiBuktiDukungBulan from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/LaporanKinerjaSaya/Edit/Bulanan/index.js";
import EditRealisasiBuktiDukungPeriode from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/LaporanKinerjaSaya/Edit/Periode/index.js";
import PenilaianBulanan from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/PenilaianBawahan/Bulanan/index.js";
import PenilaianPeriode from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/PenilaianBawahan/Periode/index.js";
import PenilaianLaporanBulanan from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/PenilaianLaporan/Bulanan/index.js";
import PenilaianLaporanPeriodik from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/PenilaianLaporan/Periodik/index.js";
import RealisasiBuktiDukungBulan from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/RealisasiBuktiDukung/Bulanan/index.js";
import RealisasiBuktiDukungPeriode from "./pages/Main/LaporanKinerja/DetailLaporanKinerja/RealisasiBuktiDukung/Periode/index.js";
import DetailSasaranKinerjaPegawai from "./pages/Main/LaporanKinerja/DetailSasaranKinerjaPegawai/index.js";
import DetailLaporanKinerjaSaya from "./pages/Main/LaporanKinerja/LaporanKinerjaSaya/Detail/index.js";
import TambahSasaranKinerjaPegawai from "./pages/Main/LaporanKinerja/LaporanKinerjaSaya/TambahSasaranKinerjaPegawai/index.js";
import MatriksPeranHasil from "./pages/Main/LaporanKinerja/MatriksPeranHasil/index.js";
import LaporanKinerjaPegawai from "./pages/Main/LaporanKinerja/index.js";
import MyProfile from "./pages/Main/My Profile/My Profile";
import Payroll from "./pages/Main/Payroll/Payroll";
import PayrollHistory from "./pages/Main/Payroll/PayrollHistory";
import DetailPayrollHistory from "./pages/Main/Payroll/PayrollHistory/DetailPayrollHistory";
import Report from "./pages/Main/Payroll/report";
import AllStage from "./pages/Main/Recruitment/All Stage";
import ArchiveApplicant from "./pages/Main/Recruitment/Archive Applicant";
import CreateRecruitment from "./pages/Main/Recruitment/Create Recruitment";
import DetailApplicant from "./pages/Main/Recruitment/Detail Applicant";
import EditRecruitment from "./pages/Main/Recruitment/Edit Recruitment";
import EntryApplication from "./pages/Main/Recruitment/Entry Application";
import Recruitment from "./pages/Main/Recruitment/Recruitment";
import RecruitmentStage from "./pages/Main/Recruitment/Recruitment Stage";
import AbsensiBawahan from "./pages/Main/Time Management/AbsensiBawahan/index.js";
import Attendance from "./pages/Main/Time Management/Attendance";
import FiturCalendar from "./pages/Main/Time Management/Calendar/index.js";
import EmployeeRecord from "./pages/Main/Time Management/Employee Record";
import Leave from "./pages/Main/Time Management/Leave";
import LeaveSetting from "./pages/Main/Time Management/Leave Setting";
import RekapCuti from "./pages/Main/Time Management/Leave/RekapCuti.js";
import MyAttendance from "./pages/Main/Time Management/My Attendance";
import PresensiManual from "./pages/Main/Time Management/My Attendance/PresensiManual.js";
import OfficialWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel";
import EditWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel/EditWorkTravel";
import ImportDataWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel/ImportDataWorkTravel";
import PerjalananDinas from "./pages/Main/Time Management/OfficialWorkTravel/verifikasi/index.js";
import Overtime from "./pages/Main/Time Management/Overtime";
import Permission from "./pages/Main/Time Management/Permission";
import RekapAbsen from "./pages/Main/Time Management/RekapAbsen/index.js";
import Schedule from "./pages/Main/Time Management/Schedule";
import TimeOf from "./pages/Main/Time Management/Time Of";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import System from "./pages/Main/Configuration/System/index.js";
import Test from "./utils/test.js";
import ImportData from "./pages/Main/Admin/ImportData/index.js";

function App() {
    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={true}
            />
            <Routes>
                {/* Auth */}
                <Route name='Login' path="/" element={<Login />} />
                <Route name='Test' path="/test" element={<Test />} />
                <Route name='Error' path="/error/:status" element={<ErrorPage />} />
                <Route name='Register' path="/register" element={<Register />} />
                <Route name='ForgotPassword' path="/forgot-password" element={<ForgotPassword />} />
                <Route name='ResetPassword' path="/auth/resetpassword/:userId/:token" element={<ResetPassword />} />
                {/* <Route name='Home' path="/" element={<Home/>}/> */}
                <Route name='Aboutus' path="/about-us" element={<Aboutus />} />
                <Route name='PrivacyPolicy' path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route name='MainBlog' path="/blogs" element={<MainBlog />} />
                <Route name='DetailBlog1' path="/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan" element={<DetailBlog1 />} />
                <Route name='DetailBlog2' path="/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik" element={<DetailBlog2 />} />
                <Route name='DetailBlog3' path="/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau" element={<DetailBlog3 />} />

                <Route path="/dashboard" element={<Template />}>
                    <Route index element={<Dashboard />} />
                    <Route name='Polling' path="/dashboard/polling" element={<Polling />} />
                    <Route name='CreatePolling' path="/dashboard/create-polling" element={<CreatePolling />} />
                    <Route name='DetailPolling' path="/dashboard/polling/detail-polling" element={<DetailPolling />} />
                    <Route name='DetailPollingAnswer' path="/dashboard/polling/detail-polling/answer" element={<DetailAnswerPolling />} />
                    <Route name='PollingForm' path="/dashboard/polling/polling-form" element={<PollingForm />} />
                    <Route name='HistoryAnnouncement' path="/dashboard/history-announcement" element={<HistoryAnnouncement />} />
                </Route>
                <Route path="/inbox" element={<Template />}>
                    <Route index element={<Inbox />} />
                    <Route name='InboxDetail' path="/inbox/inbox-detail" element={<InboxDetail />} />
                    <Route name='Approval' path="/inbox/approval" element={<Approval />} />
                </Route>
                <Route path="/news" element={<Template />}>
                    <Route index element={<News />} />
                    <Route name='NewsCreate' path="/news/news-create" element={<NewsCreate />} />
                    <Route name='NewsEdit' path="/news/news-edit" element={<NewsEdit />} />
                    <Route name='NewsView' path="/news/news-view" element={<NewsView />} />
                </Route>
                <Route path="/user-management" element={<Template />}>
                    <Route index element={<UserManagement />} />
                </Route>
                <Route path="/attendance-setting" element={<Template />}>
                    <Route index element={<PengaturanKehadiran />} />
                </Route>
                <Route path="/data-import" element={<Template />}>
                    <Route index element={<DataImport />} />
                </Route>
                <Route path="/import-data" element={<Template />}>
                    <Route index element={<ImportData />} />
                </Route>
                <Route path="/time-management-setting" element={<Template />}>
                    <Route index element={<TimeManagementSetting />} />
                </Route>
                <Route path="/employee-configuration" element={<Template />}>
                    <Route index element={<EmployeeConfiguration />} />
                </Route>
                <Route path="/pengaturan-hari-libur" element={<Template />}>
                    <Route index element={<PengaturanHariLibur />} />
                </Route>
                <Route path="/official-work-travel" element={<Template />}>
                    <Route index element={<OfficialWorkTravelSetting />} />
                </Route>
                <Route path="/payroll-setting" element={<Template />}>
                    <Route index element={<PayrollSetting />} />
                </Route>
                <Route path="/punishment" element={<Template />}>
                    <Route index element={<Punishment />} />
                </Route>
                <Route path="/hak-akses" element={<Template />}>
                    <Route index element={<HakAkses />} />
                </Route>
                <Route path="/approval-setting" element={<Template />}>
                    <Route index element={<Pelaporan />} />
                </Route>
                <Route path="/helpdesk" element={<Template />}>
                    <Route index element={<Helpdesk />} />
                </Route>
                <Route path="/device" element={<Template />}>
                    <Route index element={<Device />} />
                </Route>
                <Route path="/system" element={<Template />}>
                    <Route index element={<System />} />
                </Route>

                <Route path="/laporan-kinerja" element={<Template />}>
                    <Route index element={<LaporanKinerjaPegawai />} />
                    <Route name='DetailLaporanKinerjaSaya' path="/laporan-kinerja/detail-saya" element={<DetailLaporanKinerjaSaya />} />
                    <Route name='TambahSasaranKinerjaPegawai' path="/laporan-kinerja/tambah-skp" element={<TambahSasaranKinerjaPegawai />} />
                    <Route name='DetailSasaranKinerjaPegawai' path="/laporan-kinerja/detail-skp" element={<DetailSasaranKinerjaPegawai />} />
                    <Route name='MatriksPeranHasil' path="/laporan-kinerja/detail-skp/matriks" element={<MatriksPeranHasil />} />
                    <Route name='RealisasiBuktiDukungBulan' path="/laporan-kinerja/detail-saya/realisasi-bulanan" element={<RealisasiBuktiDukungBulan />} />
                    <Route name='RealisasiBuktiDukungPeriode' path="/laporan-kinerja/detail-saya/realisasi-periode" element={<RealisasiBuktiDukungPeriode />} />
                    <Route name='RealisasiBuktiDukungBulan' path="/laporan-kinerja/edit-saya/realisasi-bulanan" element={<EditRealisasiBuktiDukungBulan />} />
                    <Route name='RealisasiBuktiDukungPeriode' path="/laporan-kinerja/edit-saya/realisasi-periode" element={<EditRealisasiBuktiDukungPeriode />} />
                    <Route name='DetailLaporanSayaBulanan' path="/laporan-kinerja/detail-saya/bulanan" element={<DetailLaporanSayaBulanan />} />
                    <Route name='DetailLaporanSayaPeriode' path="/laporan-kinerja/detail-saya/periode" element={<DetailLaporanSayaPeriode />} />
                    <Route name='DetailLaporanKinerjaBawahanBulanan' path="/laporan-kinerja/detail-bawahan/bulanan" element={<PenilaianBulanan />} />
                    <Route name='DetailLaporanKinerjaBawahanPeriode' path="/laporan-kinerja/detail-bawahan/periode" element={<PenilaianPeriode />} />
                    <Route name='PenilaianLaporanBulanan' path="/laporan-kinerja/penilaian-bulanan" element={<PenilaianLaporanBulanan />} />
                    <Route name='PenilaianLaporanPeriodik' path="/laporan-kinerja/penilaian-periodik" element={<PenilaianLaporanPeriodik />} />
                </Route>
                {/* <Route path="/performance" element={<Template/>}>
                    <Route index element ={<Performance/>} />
                    <Route name='AddSasaranKinerjaPegawai' path="/performance/add-sasaran-kinerja-pegawai" element={<AddSasaranKinerjaPegawai/>}/>
                    <Route name='DetailSkp' path="/performance/detail-skp" element={<DetailSKP/>}/>
                    <Route name='AddEvaluation' path="/performance/detail-evaluation/add-evaluation" element={<AddEvaluation/>}/>
                    <Route name='AddEvaluationTahunan' path="/performance/add-evaluation-tahunan" element={<AddEvaluationTahunan/>}/>
                    <Route name='OutcomeRoleMatrix' path="/performance/detail-skp/outcome-role-matrix" element={<OutcomeRoleMatrix/>}/>
                    <Route name='LaporanKinerja' path="/performance/laporan-kinerja" element={<LaporanKinerja/>}/>
                    <Route name='PenilaianBawahanBulanan' path="/performance/penilaian-bawahan-bulanan" element={<PenilaianBawahanBulanan/>}/>
                    <Route name='PenilaianBawahanTahunan' path="/performance/penilaian-bawahan-tahunan" element={<PenilaianBawahanTahunan/>}/>
                    <Route name='LaporanSayaBulanan' path="/performance/laporan-saya-bulanan" element={<LaporanSayaBulanan/>}/>
                    <Route name='LaporanSayaTahunan' path="/performance/laporan-saya-tahunan" element={<LaporanSayaTahunan/>}/>
                </Route> */}

                <Route path="/performance-setting" element={<Template />}>
                    <Route index element={<PerformanceSetting />} />
                    <Route name='PreviewPerformanceSetting' path="/performance-setting/preview" element={<PreviewPerformanceSetting />} />
                    <Route name='PengaturanPerilakuKinerja' path="/performance-setting/perilaku-kerja" element={<Kinerja />} />
                    <Route name='PengaturanIndikatorKuantitatif' path="/performance-setting/indikator-kuantitatif" element={<IndikatorKuantitatif />} />
                    <Route name='PengaturanTugasStrukturalAsisten' path="/performance-setting/tugas-struktural-asisten" element={<TugasStrukturalAsisten />} />
                    <Route name='PengaturanTugasStrukturalAsisten' path="/performance-setting/tugas-pokok" element={<TugasPokok />} />
                    <Route name='PengaturanTugasStrukturalAsisten' path="/performance-setting/tugas-tambahan" element={<TugasTambahan />} />
                </Route>

                <Route path="/job-management" element={<Template />}>
                    <Route index element={<JobManagement />} />
                    <Route name='JobBulkUpload' path="/job-management/bulk-upload" element={<JobBulkUpload />} />
                </Route>
                <Route path="/subsidiary" element={<Template />}>
                    <Route index element={<Subsidiary />} />
                    <Route name='AddSubsidiary' path="/subsidiary/add-subsidiary" element={<AddSubsidiary />} />
                    <Route name='EditSubsidiary' path="/subsidiary/edit-subsidiary" element={<EditSubsidiary />} />
                    <Route name='DetailSubsidiary' path="/subsidiary/detail-subsidiary" element={<DetailSubsidiary />} />
                </Route>
                <Route path="/general-information" element={<Template />}>
                    <Route index element={<GeneralInformation />} />
                    <Route name='Structure' path="/general-information/structure" element={<Structure />} />
                    <Route name='CostProfit' path="/general-information/cost-profit" element={<CostProfit />} />
                    <Route name='Nationalities' path="/general-information/nationalities" element={<Nationalities />} />
                </Route>
                <Route path="/employee" element={<Template />}>
                    <Route index element={<EmployeeList />} />
                    <Route name='EmployeeTransfer' path="/employee/employee-transfer" element={<EmployeeTransfer />} />
                    <Route name='DetailEmployee' path="/employee/detail-employee" element={<DetailEmployee />} />
                    <Route name='AddEmployee' path="/employee/add-employee" element={<AddEmployee />} />
                    <Route name='MassResign' path="/employee/mass-resign" element={<MassResign />} />
                </Route>
                <Route path="/document" element={<Template />}>
                    <Route index element={<Document />} />
                    <Route name='OpenDocument' path="/document/open-document" element={<OpenDocument />} />
                    <Route name='Answer' path="/document/answer-document" element={<Answer />} />
                    <Route name='StatisticAnswer' path="/document/statistic-answer" element={<StatisticAnswer />} />
                </Route>
                <Route path="/finance" element={<Template />}>
                    <Route index element={<Finance />} />
                    <Route name='Reimbursement' path="/finance/reimbursement" element={<Reimbursement />} />
                    <Route name='CashAdvance' path="/finance/cash-advance" element={<CashAdvance />} />
                    <Route name='Loan' path="/finance/loan" element={<Loan />} />
                    <Route name='DetailLoan' path="/finance/detail-loan" element={<DetailLoan />} />
                    <Route name='FinanceSetting' path="/finance/setting" element={<FinanceSetting />} />
                </Route>
                <Route path="/recruitment" element={<Template />}>
                    <Route index element={<Recruitment />} />
                    <Route name='CreateRecruitment' path="/recruitment/create-recruitment" element={<CreateRecruitment />} />
                    <Route name='EditRecruitment' path="/recruitment/edit-recruitment" element={<EditRecruitment />} />
                    <Route name='EntryApplication' path="/recruitment/entry-application" element={<EntryApplication />} />
                    <Route name='DetailApplicant' path="/recruitment/detail-applicant" element={<DetailApplicant />} />
                    <Route name='ArchiveApplicant' path="/recruitment/archive-applicant" element={<ArchiveApplicant />} />
                    <Route name='RecruitmentStage' path="/recruitment/recruitment-stage" element={<RecruitmentStage />} />
                    <Route name='AllStage' path="/recruitment/all-stage" element={<AllStage />} />
                </Route>
                <Route path="/profile" element={<Template />}>
                    <Route index element={<MyProfile />} />
                </Route>
                <Route path="/time-management" element={<Template />}>
                    <Route name='MyAttendance' path="/time-management/my-attendance" element={<MyAttendance />} />
                    <Route name='MyAttendanceManual' path="/time-management/my-attendance/manual" element={<PresensiManual />} />
                    <Route name='Attendance' path="/time-management/attendance" element={<Attendance />} />
                    <Route name='EmployeeRecord' path="/time-management/employee-record" element={<EmployeeRecord />} />
                    <Route name='TimeOff' path="/time-management/time-off" element={<TimeOf />} />
                    <Route name='Leave' path="/time-management/leave" element={<Leave />} />
                    <Route name='RekapCuti' path="/time-management/leave/recap" element={<RekapCuti />} />
                    <Route name='LeaveSetting' path="/time-management/leave-setting" element={<LeaveSetting />} />
                    <Route name='Permission' path="/time-management/permission" element={<Permission />} />
                    <Route name='Overtime' path="/time-management/overtime" element={<Overtime />} />
                    <Route name='Schedule' path="/time-management/schedule" element={<Schedule />} />
                    <Route name='Calendar' path="/time-management/calendar" element={<FiturCalendar />} />
                    <Route name='AbsensiBawahan' path="/time-management/absensi-bawahan" element={<AbsensiBawahan />} />
                    <Route name='OfficialWorkTravel' path="/time-management/official-work-travel" element={<OfficialWorkTravel />} />
                    <Route name='PerjalananDinas' path="/time-management/adm-perjalanan-dinas" element={<PerjalananDinas />} />
                    <Route name='ImportDataWorkTravel' path="/time-management/official-work-travel/import-data" element={<ImportDataWorkTravel />} />
                    <Route name='EditWorkTravel' path="/time-management/official-work-travel/edit" element={<EditWorkTravel />} />
                    <Route name='DetailRekapAbsensi' path="/time-management/rekap-absen" element={<RekapAbsen />} />
                </Route>
                <Route path="/payroll" element={<Template />}>
                    <Route index element={<Payroll />} />
                    <Route name='PayrollHistory' path="/payroll/payroll-history" element={<PayrollHistory />} />
                    <Route name='DetailPayrollHistory' path="/payroll/payroll-history/detail" element={<DetailPayrollHistory />} />
                    <Route name='Report' path="/payroll/report" element={<Report />} />

                </Route>

                <Route name='ExportCuti' path="/export-cuti/:id" element={<ExportCuti />} />
                <Route name='ExportIzin' path="/export-izin/:id" element={<ExportIzin />} />
                <Route name='ExporAbsenManual' path="/export-absen-manual/:id" element={<ExporAbsenManual />} />
                <Route name='ExportLaporanKinerjaBulanan' path="/export-laporan-kinerja-bulanan" element={<ExportLaporanBulanan />} />
                {/* <Route name='ExportLaporanKinerjaPeriode' path="/export-laporan-kinerja-periode/:id" element={<ExportLaporanKinerjaPeriode/>}/> */}
                <Route name='ExportPenilaianKinerjaTahunan' path="/export-laporan-kinerja-periodik" element={<ExportLaporanPeriodik />} />
                <Route name='ExportPenilaianSKA' path="/export-penilaian-ska" element={<ExportPenilaianSKA />} />
                <Route name='ExportSka' path="/export-ska" element={<ExportSka />} />
            </Routes>
        </div>
    )
}

export default App