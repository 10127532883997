import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { BiDownload, BiFilter, BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil, HiPaperClip } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { TbFileExport } from 'react-icons/tb'
import Select from 'react-select'
import Api from '../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../components'
import checkFile from '../../../../utils/CheckFile'
import { handleLink } from '../../../../utils/handleLink'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'
import DetailOvertime from './DetailOvertime'

const Overtime = () => {
    const [showAddOvertime, setShowAddOvertime] = useState()
    const [showEditOvertime, setShowEditOvertime] = useState()
    const [showModalDelete, setShowModalDelete] = useState()

    const [data, setData] = useState('')
    const [refresh, setRefresh] = useState(false)

    //Post Overtime
    const [fullName, setFullName] = useState('')
    const [noSurat, setNoSurat] = useState('')
    const [reason, setReason] = useState('')
    const [user, setUser] = useState('')
    const [file, setFile] = useState('')
    const [namePath, setNamePath] = useState('')
    const [detailFile, setDetailFile] = useState([])

    // Handle Sign
    const handleChangeFile = (e) => {
        const fileChange = checkFile(e, 1, ['pdf', 'doc'])
        setNamePath(fileChange?.path)
        setFile(fileChange?.file)
    }

    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [overtimeId, setOvertimeId] = useState()

    //Pagination
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    // Repeater Employee
    const [jabatan, setJabatan] = useState('')
    const [idJabatan, setIdJabatan] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.user.fullname)
            setUserId(response.data.results.user.id)
            setJabatan(response?.data?.results?.user?.user_detail?.job_position?.position_name)
            setIdJabatan(response?.data?.results?.user?.user_detail?.job_position?.id)
            getDataPegawaiByCompany(response?.data?.results?.user?.companyId)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawaiPenempatan, setDataPegawaiPenempatan] = useState([])
    const getDataPegawaiByCompany = async (id) => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'), id)
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawaiPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getEmployeeById = async () => {
        try {
            const response = await Api.GetAllEmployeeById(localStorage.getItem('hris-token'), user)
            setFullName(response.data[0].fullname)
            setJabatan(response.data[0].user_detail?.job_position?.position_name)
            setIdJabatan(response.data[0].user_detail?.job_position?.id)
        } catch (error) {
            console.log(error)
        }
    }

    const [detailId, setDetailId] = useState('')

    const deleteOvertimeModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setOvertimeId(id)
        setRefresh(true)
    }

    const deleteOvertime = async () => {
        try {
            await Api.DeleteOvertime(localStorage.getItem('hris-token'), overtimeId).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }

    const openEditOvertime = async (idOvertime) => {
        setShowEditOvertime(true)
        setOvertimeId(idOvertime)
        try {
            const response = await Api.GetEditOvertimeById(localStorage.getItem('hris-token'), idOvertime)
            const data = response.data.results
            // setIdJobPosition(data.job_position)
            setFullName(data.fullname)
            setReason(data.description)
            setNoSurat(data.nomor_surat)
            setIdJabatan(data.jobPositionId)
            setJabatan(data.jobPositionId)
            setDetailFile(data.files)
            setUserId(data.userId)
            var tgl = []
            data.tanggal.forEach((val, i) => {
                tgl.push({ id: i + 1, value: val })
            });
            setRepeaters(tgl)
        } catch (error) {
            console.log(error)
        }
    }

    const editOVertime = async (id) => {
        try {

            let data = {
                userId: userId,
                jobPositionId: idJabatan,
                nomor_surat: noSurat,
                description: reason,
                // file: file
            }
            if (file) {
                data.file = file;
            }
            const formData = new FormData();

            // data2 to formData
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            // repeater income to formData
            if (repeaters?.length > 0) {
                for (const i in repeaters) {
                    formData.append('tanggal[]', repeaters[i].value);
                }
            } else {
                toast.error("Tanggal Lemmbur Tidak Boleh Kosong")
            }
            // for (const pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            await Api.UpdateOvertime(localStorage.getItem('hris-token'), formData, overtimeId).then((response) => {
                resetForm()
                setShowEditOvertime(!showEditOvertime)
                setRefresh(true)
                toast.success('Berhasil Edit Data!')
            }).catch((error) => {
                console.log(error)
                const e = error.response.data.errors
                toast.error(e)
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data!')
        }
    }

    const resetForm = () => {
        setFullName('')
        setReason('')
        setUser('')
        setNoSurat('')
        setFile('')
        setNamePath('')
        setRepeaters([
            { id: 1, value: '' },
            // Tambahkan lebih banyak objek jika diperlukan
        ])
    }

    const postOvertime = async () => {
        try {
            const data = {
                userId: localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ? user : userId,
                jobPositionId: idJabatan,
                nomor_surat: noSurat,
                description: reason,
                file: file
            }
            const formData = new FormData();

            // data2 to formData
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            // repeater income to formData
            if (repeaters?.length > 0) {
                for (const i in repeaters) {
                    formData.append('tanggal[]', repeaters[i].value);
                }
            } else {
                toast.error("Tanggal Lemmbur Tidak Boleh Kosong")
            }
            // for (const pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            try {
                await Api.PostOvertime(localStorage.getItem('hris-token'), formData).then((response) => {
                    resetForm()
                    setShowAddOvertime(!showAddOvertime)
                    setRefresh(true)
                    toast.success('Berhasil Menambahkan Data!')
                }).catch((error) => {
                    console.log(error)
                    const e = error.response.data.errors
                    toast.error(e)
                })
            } catch (error) {
                console.log(error)
            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const admin = localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY'

    const [showFilter, setShowFilter] = useState(false)
    const [penempatan, setPenempatan] = useState("")
    const [unitKerja, setUnitKerja] = useState("")
    const [startDateFilter, setStartDateFilter] = useState("")
    const [endDateFilter, setEndDateFilter] = useState("")
    const [fullnameFilter, setFullnameFilter] = useState("")

    const getOvertime = async () => {
        try {
            if (admin) {
                const response = await Api.GetOvertimeByAdmin(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, fullnameFilter, currentPage, limit, penempatan, unitKerja)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
            } else {
                const response = await Api.GetOvertime(localStorage.getItem('hris-token'), startDateFilter, endDateFilter, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [showModalDeleteFile, setShowModalDeleteFile] = useState(false)
    const [idFile, setIdFile] = useState(false)

    const deleteModal = (id) => {
        setShowModalDeleteFile(!showModalDeleteFile)
        setIdFile(id)
        setRefresh(true)
    }

    const deleteFile = async () => {
        try {
            await Api.DeleteFileBuktiDukung(localStorage.getItem('hris-token'), idFile).then((response) => {
                setRefresh(true)
                openEditOvertime(overtimeId)
                setShowModalDeleteFile(false)
                toast.success('Hapus File Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }
    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), penempatan)
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results)
            // console.log(response, 'employeeo')
        } catch (error) {
            console.log(error)
        }
    }

    const handleResetFilter = () => {
        setStartDateFilter("")
        setEndDateFilter("")
        setFullnameFilter("")
        setPenempatan("")
        setUnitKerja("")
        setShowFilter(!showFilter)
        // window.location.reload()
    }

    const [repeaters, setRepeaters] = useState([
        { id: 1, value: '' },
        // Tambahkan lebih banyak objek jika diperlukan
    ]);

    // Handler untuk mengubah nilai overtOnDate di dalam repeater
    const handleOvertOnDateChange = (id, value) => {
        const updatedRepeaters = repeaters.map((item) =>
            item.id === id ? { ...item, value: value } : item
        );
        setRepeaters(updatedRepeaters);
    };

    // Handler untuk menambahkan repeater baru
    const handleAddRepeater = () => {
        const newRepeater = {
            id: repeaters.length + 1,
            overtOnDate: '',
        };
        setRepeaters([...repeaters, newRepeater]);
    };
    // Handler untuk menghapus repeater berdasarkan ID
    const handleRemoveRepeater = (id) => {
        const updatedRepeaters = repeaters.filter((item) => item.id !== id);
        setRepeaters(updatedRepeaters);
    };

    const [detail, setDetail] = useState(false)

    useEffect(() => {
        GetData()
        getOvertime()
        setRefresh(false)
    }, [startDateFilter, endDateFilter, fullnameFilter, limit, currentPage, penempatan, unitKerja, refresh])

    useEffect(() => {
        getEmployeeById()
    }, [user])

    useEffect(() => {
        getDataPegawai()
        FetchUser()
        setRefresh(false)
    }, [refresh, limit])



    return (
        <div>
            <Modal
                activeModal={showFilter}
                buttonClose={() => setShowFilter(!showFilter)}
                width={'550px'}
                title={
                    <div className='flex gap-[27px] items-center'>
                        <BsFilter className='text-dark-1 text-2xl' />
                        <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                    </div>
                }
                content={
                    <div className='space-y-[20px]'>
                        <div className='grid grid-cols-2 gap-3'>
                            <div className='relative'>
                                <select onChange={(e) => setPenempatan(e.target.value)} value={penempatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                    {dataPenempatan && dataPenempatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja</option>
                                    {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='col-span-2'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Tanggal</h1>
                                <div className='flex gap-3 items-center'>
                                    <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>-</h1>
                                    <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-between'>
                            <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                            <button onClick={() => setShowFilter(!showFilter)} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showAddOvertime}
                title={languages.language === 'id' ? "Tambah Lembur" : "Add Overtime"}
                buttonClose={() => { setShowAddOvertime(!showAddOvertime); resetForm() }}
                width={'553px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        {localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                            (
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}  <span className='text-[#780000]'>*</span></h2>
                                    {/* <div className='relative'>
                                        <select onChange={(e) => setUser(e.target.value)} value={user} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                            <option selected value={''}>{languages.language === 'id' ? "Pilih Pegawai..." : "Select Employee..."}</option>
                                            {Object.values(dataEmployee).map((item) => {
                                                if (item.role === 'EMPLOYEE') {
                                                    return (
                                                        <option value={item.id}>{item.fullname}</option>
                                                    )
                                                } else {
                                                    return ''
                                                }
                                            })}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div> */}

                                    {localStorage.getItem('hris-role') === 'ADMIN' ?
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultInputValue={userId}
                                            options={dataPegawai}
                                            onChange={(e) => {
                                                // setIdUserLogin(e.value)
                                                setUser(e.value)
                                            }}
                                        />
                                        : <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultInputValue={userId}
                                            options={dataPegawaiPenempatan}
                                            onChange={(e) => {
                                                // setIdUserLogin(e.value)
                                                setUser(e.value)
                                            }}
                                        />
                                    }
                                </div>
                            ) :
                            (
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                                    </div>
                                </div>
                            )}

                        <div className='w-full'>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Jabatan <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <p className='min-h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none'>{jabatan}</p>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Nomor Surat Perintah Lembur  <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setNoSurat(e.target.value)} value={noSurat} type='text' className='h-[35px] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Input Nomor Surat perintah lembur...' />
                        </div>
                        <div className='mb-[28px]'>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Surat Perintah Lembur <span className='text-[#780000]'>*</span></h1>
                            <div className='flex items-center gap-[13px]'>
                                <label htmlFor='upload-file' className='cursor-pointer'>
                                    <div className='flex w-[473px] h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                        <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                            <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                        </div>
                                        <h1 className='text-zinc-700 text-xs font-medium'>{namePath ? namePath : 'Tidak ada file yang dipilih'}</h1>
                                        <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                    </div>
                                </label>
                            </div>
                            <h1 className=" text-red-600 text-[8px] font-normal">*(Maksimum file 1 Mb, Ekstensi PDF)</h1>
                        </div>
                        <div className='w-full'>
                            {repeaters.map((repeater) => (
                                <div key={repeater.id} className='mb-[10px] w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>
                                        {languages.language === 'id' ? 'Tanggal Lembur' : 'Overtime on date'} <span className='text-[#780000]'>*</span>
                                    </h1>
                                    <div className='flex gap-3 '>
                                        <input
                                            onChange={(e) => handleOvertOnDateChange(repeater.id, e.target.value)}
                                            value={repeater.value}
                                            type='date'
                                            className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full'
                                        />

                                        <button onClick={() => handleRemoveRepeater(repeater.id)}>
                                            <CgTrashEmpty className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {repeaters.length > 4 ?
                                null :
                                <button onClick={handleAddRepeater} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                    <AiOutlinePlus />
                                    <h1 className="text-xs font-medium">Tambah Tanggal</h1>
                                </button>
                            }
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Deskripsi" : "Description"} <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setReason(e.target.value)} value={reason} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  w-full text-[#A8A8A8] resize-none' placeholder={languages.language === 'id' ? "Alasan Lembur" : "Overtime reason..."} />
                        </div>
                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAddOvertime(!showAddOvertime); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={postOvertime} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah" : "Add"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditOvertime}
                title={languages.language === 'id' ? "Edit Lembur" : "Edit Overtime"}
                buttonClose={() => { setShowEditOvertime(!showEditOvertime); resetForm() }}
                width={'553px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h2 className='text-dark-5 text-[12px] font-medium mb-[5px]'>Employee Name  <span className='text-[#780000]'>*</span></h2>
                            <input value={fullName} type='text' className='h-[35px] bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full' placeholder={fullName} readOnly />
                        </div>
                        {/* <div className='w-full'>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Jabatan <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={jabatan} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                            </div>
                        </div> */}
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Nomor Surat Perintah Lembur  <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setNoSurat(e.target.value)} value={noSurat} type='text' className='h-[35px] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Input Nomor Surat perintah lembur...' />
                        </div>
                        <div className='mb-[28px]'>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Surat Perintah Lembur <span className='text-[#780000]'>*</span></h1>
                            <div className='flex items-center gap-[13px]'>
                                <label htmlFor='upload-file' className='cursor-pointer'>
                                    <div className='flex w-[473px] h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                        <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                            <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                        </div>
                                        <h1 className='text-zinc-700 text-xs font-medium'>{namePath ? namePath : 'Tidak ada file yang dipilih'}</h1>
                                        <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                    </div>
                                </label>
                            </div>
                            <h1 className=" text-red-600 text-[8px] font-normal">*(Maksimum file 1 Mb, Ekstensi PDF)</h1>
                        </div>
                        {detailFile && detailFile.map((file, idx) => (
                            <div key={idx} className='flex items-center gap-3'>
                                <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                <a href={imageHandle(file?.path)} download={file?.originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2' rel="noreferrer">
                                    <BiDownload className='text-xl' />
                                </a>
                                <button
                                    onClick={() => deleteModal(file.id)}
                                >
                                    <CgTrashEmpty className='text-[#003049]' />
                                </button>
                            </div>
                        ))}
                        <div className='w-full'>
                            {repeaters.map((repeater) => (
                                <div key={repeater.id} className='mb-[10px] w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>
                                        {languages.language === 'id' ? 'Tanggal Lembur' : 'Overtime on date'}{' '}
                                        <span className='text-[#780000]'>*</span>
                                    </h1>
                                    <div className='flex gap-3 '>
                                        <input
                                            onChange={(e) => handleOvertOnDateChange(repeater.id, e.target.value)}
                                            value={repeater.value}
                                            type='date'
                                            className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full'
                                        />

                                        <button onClick={() => handleRemoveRepeater(repeater.id)}>
                                            <CgTrashEmpty className='text-[#780000] text-xl' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {repeaters.length > 4 ?
                                null :
                                <button onClick={handleAddRepeater} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                    <AiOutlinePlus />
                                    <h1 className="text-xs font-medium">Tambah Tanggal</h1>
                                </button>
                            }
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Deskripsi" : "Description"}<span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setReason(e.target.value)} value={reason} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  w-full text-[#A8A8A8] resize-none' placeholder={languages.language === 'id' ? "Alasan Lembur" : "Overtime reason..."} />
                        </div>
                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowEditOvertime(!showEditOvertime); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={editOVertime} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Submit" : "Save"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteFile}
                buttonClose={() => setShowModalDeleteFile(!showModalDeleteFile)}
                submitButton={deleteFile}
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteOvertime}
            />
            {!detail ?
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[20px]'>{languages.language === 'id' ? "Lembur" : "Overtime"}</h1>
                        <h2 className='text-[10px] text-dark-5'>{languages.language === 'id' ? "Daftar Lembur Pegwai" : "List of  Overtime Employee"}</h2>
                    </div>

                    {admin ? (
                        <div className="flex flex-wrap justify-between items-center gap-2 mt-[31px]">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={() => setShowFilter(!showFilter)} className='bg-white border w-fit rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiFilter className='text-[#0E5073]' />
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Filter</h1>
                                </button>
                                <a href={handleLink(`overtime/export?bulan=${moment(startDateFilter).format("M")}&tahun=${moment(startDateFilter).format("YYYY")}&userId=${userId}`)} target='_blank' className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]' rel="noreferrer">
                                    <TbFileExport className='text-[#003049]' />
                                    <h1 className='text-[#003049] text-sm font-[500]'>Ekspor</h1>
                                </a>
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div onChange={(e) => setFullnameFilter(e.target.value)} className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                    <input placeholder='Cari Nama Pegawai...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[200px]' />
                                </div>

                                <button onClick={() => setShowAddOvertime(!showAddOvertime)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white' />
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Lembur" : "Add Overtime"}</h1>
                                </button>
                            </div>
                        </div>
                    )
                        :
                        <div className="flex flex-wrap justify-between gap-2 mt-[31px]">
                            <div className="flex flex-wrap items-center gap-2">
                                <input onChange={(e) => setStartDateFilter(e.target.value)} value={startDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                <p>-</p>
                                <input onChange={(e) => setEndDateFilter(e.target.value)} value={endDateFilter} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={() => setShowAddOvertime(!showAddOvertime)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white' />
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Lembur" : "Add Overtime"}</h1>
                                </button>
                            </div>
                        </div>
                    }

                    <div>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Nomor Surat Perintah Lembur</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Tanggal Lembur" : "Date of Filling"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Alasan" : "Reason"}</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                </div>
                                {data && data.map((val, index) => (
                                    <div key={index} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val.fullname ? val.fullname : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val.nomor_surat ? val.nomor_surat : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val.jabatan ? val.jabatan : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <ul className='list-disc list-inside'>
                                                {val.tanggal && val.tanggal.map((item, idx) => (
                                                    <li key={idx} className='text-dark-5 text-[11px] font-[500] truncate'>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{val?.description ?? ""}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-start gap-[12px]'>
                                            <button
                                                onClick={() => {
                                                    setDetailId(val.id)
                                                    setDetail(true)
                                                }}
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <AiOutlineEye className='text-[#003049]' />
                                            </button>
                                            <button
                                                onClick={() => openEditOvertime(val.id)}
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]' />
                                            </button>
                                            <button
                                                onClick={() => deleteOvertimeModal(val.id)}
                                                className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]' />
                                            </button>

                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limitData={limit}
                            lengthData={totalCount}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>

                </div>
                : <DetailOvertime id={detailId} />
            }
        </div>
    )
}

export default Overtime