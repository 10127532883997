import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Pagination, } from '../../../../../components'
import languages from '../../../../../utils/languages'
import { IconPlus } from '../../../../../assets'
import IconPrimaryButton from '../../../../../components/Button/IconPrimaryButton'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'

function UnitKerja() {
    const [showModalAddUnitKerja, setShowModalAddUnitKerja] = useState(false)
    const [showModalEditUnitKerja, setShowModalEditUnitKerja] = useState(false)
    const [showModalDeleteUnitKerja, setShowModalDeleteUnitKerja] = useState(false)

    // Pagination
    const limit = 10
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const resetForm = () => {
        setunitKerjaName('')
        setSubsidiary('')
    }

    // Unit Kerja
    const [unitKerjaName, setunitKerjaName] = useState('')
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [idUnitKerja, setIdUnitKerja] = useState('')
    const [dataSubsidiary, setDataSubsidiary] = useState([])
    const [subsidiary, setSubsidiary] = useState('')

    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            setDataSubsidiary(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const createUnitKerja = async () => {
        if (!unitKerjaName.trim()) {
            toast.error('Nama Unit Kerja Tidak Boleh Kosong!')
        } else if (!subsidiary.trim()) {
            toast.error('Penempatan Tidak Boleh Kosong!')
        } else {
            const data = {
                name: unitKerjaName,
                companyId: subsidiary
            }
            try {
                await Api.CreateUnitKerja(localStorage.getItem('hris-token'), data).then((res) => {
                    resetForm()
                    setRefresh(true)
                    toast.success('Berhasil Menambahkan Data!')
                    setShowModalAddUnitKerja(!showModalAddUnitKerja)
                }).catch((err) => {
                    console.log(err)
                })
            } catch (error) {
                toast.error('Gagal Menambahkan Data!')
            }
        }
    }

    const openUnitKerja = async (id) => {
        setShowModalEditUnitKerja(!showModalEditUnitKerja)
        setIdUnitKerja(id)
        try {
            const response = await Api.GetUnitKerjaById(localStorage.getItem('hris-token'), id)
            setunitKerjaName(response.data.name)
            setSubsidiary(response.data.companyId)
        } catch (error) {
            console.log(error)
        }
    }

    const editUnitKerja = async () => {
        if (!unitKerjaName.trim()) {
            toast.error('Nama Unit Kerja Tidak Boleh Kosong!')
        } else if (!subsidiary.trim()) {
            toast.error('Penempatan Tidak Boleh Kosong!')
        } else {
            const data = {
                name: unitKerjaName,
                companyId: subsidiary
            }
            try {
                await Api.UpdateUnitKerja(localStorage.getItem('hris-token'), data, idUnitKerja).then((res) => {
                    resetForm()
                    setRefresh(true)
                    toast.success('Berhasil Edit Data!')
                    setShowModalEditUnitKerja(!showModalEditUnitKerja)
                }).catch((err) => {
                    console.log(err)
                })
            } catch (error) {
                toast.error('Gagal Edit Data!')
            }
        }
    }

    const getUnitKerja = async () => {
        try {
            const response = await Api.GetUnitKerja(localStorage.getItem('hris-token'), currentPage, limit, name)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
            setDataUnitKerja(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const deleteUnitKerja = async () => {
        try {
            await Api.DeleteUnitKerja(localStorage.getItem('hris-token'), idUnitKerja).then((res) => {
                setShowModalDeleteUnitKerja(!showModalDeleteUnitKerja)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }

    const deleteUnitKerjaModal = async (id) => {
        setShowModalDeleteUnitKerja(!showModalDeleteUnitKerja)
        setIdUnitKerja(id)
    }

    const [name, setName] = useState("")

    // Implementasi fungsi debounce
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Event handler untuk input perubahan dengan debounce
    const debouncedSetName = useCallback(debounce((value) => setName(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };

    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setRefresh(false)
        getUnitKerja()
    }, [refresh, name])

    useEffect(() => {
        getSubsidiary()
        Fetch()
    }, [])

    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showModalAddUnitKerja}
                    title={'Tambah Unit Kerja'}
                    buttonClose={() => { setShowModalAddUnitKerja(!showModalAddUnitKerja); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Nama Unit Kerja<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setunitKerjaName(e.target.value)} value={unitKerjaName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={"Input nama unit kerja..."} />
                            </div>
                            <div className='w-full'>
                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kantor Cabang/Penempatan<span className='text-red-primer'>*</span></h2>
                                <div className='relative'>
                                    <select onChange={(e) => setSubsidiary(e.target.value)} value={subsidiary} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>Pilih Kantor Cabang/Penempatan...</option>
                                        {Object.values(dataSubsidiary).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.company_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalAddUnitKerja(!showModalAddUnitKerja); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => createUnitKerja()} text={'Simpan'} />
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <Modal
                    activeModal={showModalEditUnitKerja}
                    title={'Edit Unit Kerja'}
                    buttonClose={() => { setShowModalEditUnitKerja(!showModalEditUnitKerja); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Nama Unit Kerja<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setunitKerjaName(e.target.value)} value={unitKerjaName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={'Input nama unit kerja...'} />
                            </div>
                            <div className='w-full'>
                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kantor Cabang/Penempatan<span className='text-red-primer'>*</span></h2>
                                <div className='relative'>
                                    <select onChange={(e) => setSubsidiary(e.target.value)} value={subsidiary} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected disabled value={''}>Pilih Kantor Cabang/Penempatan...</option>
                                        {Object.values(dataSubsidiary).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.company_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalEditUnitKerja(!showModalEditUnitKerja); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => editUnitKerja()} text={'Simpan'} />
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <ModalDelete
                    activeModal={showModalDeleteUnitKerja}
                    buttonClose={() => setShowModalDeleteUnitKerja(!showModalDeleteUnitKerja)}
                    submitButton={deleteUnitKerja}
                />
            </div>
            <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                <div className='flex flex-wrap justify-between items-center gap-2 mb-[36px]'>
                    <div className='w-full lg:w-auto'>
                        <h1 className='text-dark-3 text-[16px] font-bold'>Unit Kerja</h1>
                        <h1 className='text-grey-thick text-xs font-medium'>Daftar Unit Kerja</h1>
                    </div>
                    <div className='flex flex-wrap gap-2'>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => handleInputChange(e)} placeholder='Cari ...' className='text-[#A8A8A8] text-[12px] font-[500] pl-12 border rounded-md py-2 w-full lg:w-[200px]' />
                        </div>
                        {role === 'ADMIN' ?
                            <IconPrimaryButton icon={IconPlus} action={() => setShowModalAddUnitKerja(!showModalAddUnitKerja)} text={'Tambah Unit Kerja'} />
                            : null
                        }
                    </div>
                </div>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[600px] max-w-[600px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>Nama Unit Kerja</h1>
                                    <MdImportExport className='text-grey-thick text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>Penempatan/Kantor Cabang</h1>
                                    <MdImportExport className='text-grey-thick text-xs' />
                                </div>
                                {role === 'ADMIN' ?
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                    : null
                                }
                            </div>
                            {Object.values(dataUnitKerja).map((data, index) => {
                                return (
                                    <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[600px] max-w-[600px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.name}</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data?.company?.company_name}</h1>
                                        </div>
                                        {role === 'ADMIN' ?
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <button onClick={() => openUnitKerja(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => deleteUnitKerjaModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={dataUnitKerja?.length}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default UnitKerja