import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Navbar } from '../../../../components'
import OutputPerhitungan from './OutputPerhitungan'
import RekapAbsen from './RekapAbsen'
import RekapPenghasilan from './RekapPenghasilan'
import RekapPotongan from './RekapPotongan'
const Report = () => {
    const params = useLocation()?.state?.company
    const [category, setCategory] = useState("RekapAbsen")
    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen space-y-[40px] overflow-hidden'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Lihat Laporan Penghasilan'} LinkBack={'/dashboard'} />
                </div>
                <div className='bg-white px-[28px] py-[13px] mb-5 border rounded-[6px] shadow-sm space-y-[27px]'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        <button onClick={() => setCategory('RekapAbsen')} className={`px-[20px] outline-none pb-[10px] ${category === 'RekapAbsen' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[30px]`}>Rekap Absen </button>
                        <button onClick={() => setCategory('RekapPotongan')} className={`px-[20px] outline-none pb-[10px] ${category === 'RekapPotongan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[30px]`}>Rekap Potongan</button>
                        <button onClick={() => setCategory('RekapPenghasilan')} className={`px-[20px] outline-none pb-[10px] ${category === 'RekapPenghasilan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[30px]`}>Rekap Penghasilan</button>
                        <button onClick={() => setCategory('OutputPerhitungan')} className={`px-[20px] outline-none pb-[10px] ${category === 'OutputPerhitungan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[30px]`}>Output Perhitungan</button>
                    </div>
                    {category === 'RekapAbsen' ?
                        <RekapAbsen idcompany={params} />
                        : category === 'RekapPotongan' ?
                            <RekapPotongan idcompany={params} />
                            : category === 'RekapPenghasilan' ?
                                <RekapPenghasilan idcompany={params} />
                                : category === 'OutputPerhitungan' ?
                                    <OutputPerhitungan idcompany={params} />
                                    : null

                    }
                </div>
            </div>
        </div>
    )
}

export default Report