import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport } from 'react-icons/md'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../../components'
import languages from '../../../../../utils/languages'
import IconPrimaryButton from '../../../../../components/Button/IconPrimaryButton'
import { IconPlus } from '../../../../../assets'
import SecondaryButton from '../../../../../components/Button/SecondaryButton'
import PrimaryButton from '../../../../../components/Button/PrimaryButton'

function JenisJabatan() {
    const [showModalAddJobLevel, setShowModalAddJobLevel] = useState(false)
    const [showModalEditJobLevel, setShowModalEditJobLevel] = useState(false)
    const [showModalDeleteJobLevel, setShowModalDeleteJobLevel] = useState(false)

    // Pagination
    const limit = 10
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // Job Level

    const [dataJobLevel, setDataJobLevel] = useState('')
    const [jobLevelName, setJobLevelName] = useState('')
    const [idJobLevel, setIdJobLevel] = useState('')

    const resetForm = () => {
        setJobLevelName('')
    }

    const OpenEditJobLevel = async (id) => {
        setIdJobLevel(id)
        setShowModalEditJobLevel(!showModalEditJobLevel)
        try {
            const response = await Api.GetJobLevelByID(localStorage.getItem('hris-token'), id)
            setJobLevelName(response.data[0].level_name)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobLevel = async () => {
        try {
            const response = await Api.GetJobLevel(localStorage.getItem('hris-token'), currentPage, limit, name)
            setDataJobLevel(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobLevel = async () => {
        if (!jobLevelName.trim()) {
            toast.error('Nama Jenis Jabatan Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    level_name: jobLevelName
                }
                await Api.PostJobLevel(localStorage.getItem('hris-token'), data).then((res) => {
                    setShowModalAddJobLevel(!showModalAddJobLevel)
                    setRefresh(true)
                    resetForm()
                    toast.success('Berhasil Menambahkan Data!')
                }).catch((err) => {
                    console.log(err)
                })
            } catch (error) {
                toast.error('Gagal Menambahkan Data!')
            }
        }
    }

    const editJobLevel = async () => {
        if (!jobLevelName.trim()) {
            toast.error('Nama Jenis Jabatan Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    level_name: jobLevelName
                }
                await Api.UpdateJobLevel(localStorage.getItem('hris-token'), data, idJobLevel).then((res) => {
                    setShowModalEditJobLevel(!showModalEditJobLevel)
                    setRefresh(true)
                    resetForm()
                    toast.success('Berhasil Edit Data!')
                }).catch((err) => {
                    console.log(err)
                })
            } catch (error) {
                toast.error('Gagal Edit Data!')
            }
        }
    }

    const deleteJobLevelModal = async (id) => {
        setShowModalDeleteJobLevel(!showModalDeleteJobLevel)
        setIdJobLevel(id)
    }

    const deleteJobLevel = async (id) => {
        try {
            await Api.DeleteJobLevel(localStorage.getItem('hris-token'), idJobLevel).then((res) => {
                setShowModalDeleteJobLevel(!showModalDeleteJobLevel)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }

    const [name, setName] = useState("")

    // Implementasi fungsi debounce
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Event handler untuk input perubahan dengan debounce
    const debouncedSetName = useCallback(debounce((value) => setName(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };

    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getJobLevel()
        Fetch()
        setRefresh(false)
    }, [refresh, name])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showModalAddJobLevel}
                    title={languages.language === 'id' ? "Tambah Jenis Jabatan" : "Add Jenis Jabatan"}
                    buttonClose={() => { setShowModalAddJobLevel(!showModalAddJobLevel); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Jenis Jabatan" : "Job Level Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setJobLevelName(e.target.value)} value={jobLevelName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={"Nama Jenis Jabatan..."} />
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalAddJobLevel(!showModalAddJobLevel); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => postJobLevel()} text={'Simpan'} />
                            </div>
                        </div>
                    }
                /></div>
            <div className='relative'>
                <Modal
                    activeModal={showModalEditJobLevel}
                    title={'Edit Jenis Jabatan'}
                    buttonClose={() => { setShowModalEditJobLevel(!showModalEditJobLevel); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Jenis Jabatan" : "Job Level Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setJobLevelName(e.target.value)} value={jobLevelName} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={"Nama Jenis Jabatan..."} />
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <SecondaryButton action={() => { setShowModalEditJobLevel(!showModalEditJobLevel); resetForm() }} text={'Batal'} />
                                <PrimaryButton action={() => editJobLevel()} text={'Simpan'} /> </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <ModalDelete
                    activeModal={showModalDeleteJobLevel}
                    buttonClose={() => setShowModalDeleteJobLevel(!showModalDeleteJobLevel)}
                    submitButton={deleteJobLevel}
                />
            </div>
            <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                <div className='flex flex-wrap justify-between items-center gap-2 mb-[36px]'>
                    <div className='w-full lg:w-auto'>
                        <h1 className='text-dark-3 text-[16px] font-bold'>Jenis Jabatan</h1>
                        <h1 className='text-grey-thick text-xs font-medium'>Daftar Jenis Jabatan</h1>
                    </div>
                    <div className='flex flex-wrap gap-2'>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => handleInputChange(e)} placeholder='Cari ...' className='text-[#A8A8A8] text-[12px] font-[500] pl-12 border rounded-md py-2 w-full lg:w-[200px]' />
                        </div>
                        {role === 'ADMIN' ?
                            <IconPrimaryButton icon={IconPlus} action={() => setShowModalAddJobLevel(!showModalAddJobLevel)} text={'Tambah Jenis Jabatan'} />
                            : null
                        }
                    </div>
                </div>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex justify-between items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Nama Jenis Jabatan" : "Level Name"}</h1>
                                    <MdImportExport className='text-grey-thick text-xs' />
                                </div>
                                {role === 'ADMIN' ?
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                    : null
                                }
                            </div>
                            {Object.values(dataJobLevel).map((data, index) => {
                                return (
                                    <div key={index} className='flex justify-between items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.level_name}</h1>
                                        </div>
                                        {role === 'ADMIN' ?
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <button onClick={() => OpenEditJobLevel(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => deleteJobLevelModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={dataJobLevel?.length}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default JenisJabatan