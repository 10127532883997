import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Api from '../../../../../Api'
import { Navbar } from '../../../../../components'
import languages from '../../../../../utils/languages'

const ImportDataWorkTravel = () => {
    const [file, setFile] = useState()
    const [namePath, setNamePath] = useState('')
    const [selected, setSelected] = useState([])
    const [userId, setUserId] = useState('')
    const [jobPosition, setJobPosition] = useState('')
    const [dataWorkTravelType, setDataWorkTravelType] = useState('')


    const [userName, setUserName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [purpose, setPurpose] = useState('')
    const [location, setLocation] = useState('')
    const [workTravelType, setWorkTravelType] = useState('')
    const [noSurat, setNoSurat] = useState('')
    const navigate = useNavigate()

    const handleChange = (selectedOption) => {
        const data = []
        selectedOption && selectedOption.map(val => (
            data.push(val.value)
        ))
        setSelected(selectedOption)
        setUserId(data)
    }

    const createOfficialWorkTravel = async () => {
        const data = {
            office_work_travel: workTravelType,
            start_date: startDate,
            end_date: endDate,
            location: location,
            purpose: purpose,
            userId: admin ? userId : [userId],            // Replace with a valid user ID
            file: file,
            nomor_surat: noSurat
        }
        console.log(data)
        const formData = new FormData();
        formData.append('office_work_travel', data.office_work_travel);
        formData.append('start_date', data.start_date);
        formData.append('end_date', data.end_date);
        formData.append('location', data.location);
        formData.append('purpose', data.purpose);

        // For array elements, you can append them individually
        data.userId.forEach((id, index) => {
            formData.append(`userId[${index}]`, id);
        });

        // Handling file
        if (data.file) {
            formData.append('file', data.file);
        }

        formData.append('nomor_surat', data.nomor_surat);
        for (const pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        try {
            await Api.CreateWorkTravel(localStorage.getItem('hris-token'), formData).then((response) => {
                toast.success('Berhasil Menambahkan Data')
                navigate(-1)
            }).catch((error) => {
                console.log(error)
                toast.error(error?.response?.data?.errors ?? error?.response?.data?.message)
                // const e = error?.response?.data?.errors?.error?.details
                // for (let i = 0; i < e.length; i++) {
                //     toast.error(e[i]?.message)
                // }
            })
        } catch (error) {
            console.log(error)
        }
    }
    console.log(userId)

    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.user.fullname)
            setUserId(response.data.results.user.id)
            getDataPegawaiByCompany(response?.data?.results?.user?.companyId)
        } catch (error) {
            console.log(error)
        }
    }

    const admin = localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY'

    const GetEmployeeById = async () => {
        try {
            const response = await Api.GetEmployeeById(localStorage.getItem('hris-token'), userId)
            setJobPosition(response.data[0].user_detail.job_position.position_name)
        } catch (error) {

        }
    }

    const GetWorkTravelType = async () => {
        try {
            const response = await Api.GetWorkTravelSetting(localStorage.getItem('hris-token'))
            setDataWorkTravelType(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat pdf atau word')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawaiPenempatan, setDataPegawaiPenempatan] = useState([])
    const getDataPegawaiByCompany = async (id) => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'), id)
            const data = response.data.data
            // console.log("aceh", response)
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawaiPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        FetchUser()
        getDataPegawai()
        GetWorkTravelType()
    }, [])

    useEffect(() => {
        GetEmployeeById()
    }, [userId])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Perjalanan Dinas" : "Official Work Travel"} LinkBack={'/time-management/official-work-travel'} />
                <div className='space-y-[24px] bg-white px-[20px] py-[34px] rounded-[12px] border'>
                    <div>
                        <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Data Perjalanan Dinas" : "Official Work Travel Data"}</h1>
                        {/* <div className="text-gray-500 text-sm font-normal">{languages.language === 'id' ?"Impor File untuk memperbarui template data perjalanan dinas":"Import a file to update template official work trip data"}</div> */}
                    </div>
                    {/* <div className='w-[605px] mb-[13px]'>
                            <h1 className='text-zinc-700 text-base font-bold mb-[20px]'>{languages.language === 'id' ? "Ikuti langkah-langkah berikut ini untuk melakukan import data perjalanan dinas yang benar " : "Follow these steps to perform the correct import official work travel data "}:</h1>
                            <h1 className='text-sm'>{languages.language === 'id' ? "Ikuti langkah-langkah berikut untuk melakukan impor yang benar" : "Follow these steps to perform the correct import"}</h1><br />
                            <h1 className='text-sm'>1. {languages.language === 'id' ? "Pilih Nama Pegawai" : "Select employee’s name"}</h1>
                            <h1 className='text-sm'>2. {languages.language === 'id' ? "Pastikan data yang anda pilih sudah benar" : "Make sure the data you choose is correct"}</h1>
                        </div> */}
                    <div className='flex w-full justify-between gap-[16px]'>
                        {localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"} <span className='text-red-primer'>*</span></h1>
                                {/* <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setUserId(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Pegawai" : "Select Employee..."}</option>
                                                {Object.values(dataEmployeeAll).map((item, idx) => (
                                                    <option key={idx} value={item.id}>{item.fullname}</option>
                                                ))}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div> */}

                                {localStorage.getItem('hris-role') === 'ADMIN' ?
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultInputValue={userId}
                                        options={dataPegawai}
                                        onChange={(e) => {
                                            // setIdUserLogin(e.value)
                                            handleChange(e)
                                        }}
                                    />
                                    : <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultInputValue={userId}
                                        options={dataPegawaiPenempatan}
                                        onChange={(e) => {
                                            // setIdUserLogin(e.value)
                                            handleChange(e)
                                        }}
                                    />
                                }
                            </div>
                            :
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                                </div>
                            </div>
                        }
                        {!admin ?
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                <div className='py-[10px] bg-[#DCDCDC] text-dark-3 px-[33px] text-xs rounded-md'>{jobPosition ? jobPosition : 'Employee not selected'}</div>
                            </div>
                            : null
                        }
                    </div>

                    <hr className='border-1' />
                    {/* 
                    <div>
                        <h1 className="text-zinc-800 text-base font-bold">{languages.language === 'id' ?"Unggah template perjalanan dinas":"Upload template official work travel data"}</h1>
                        <p className="">
                            <span className="text-zinc-700 text-sm font-normal">{languages.language === 'id' ?"Ikuti langkah-langkah berikut untuk melakukan impor yang benar":"Follow these steps to perform the correct import"}<br/></span>
                            <span className="text-zinc-700 text-sm font-normal">1. {languages.language === 'id' ?"Pilih":"Click"} </span>
                            <span className="text-zinc-700 text-sm font-bold">'{languages.language === 'id' ?"Unduh Template":"Download Template"}'<br/></span>
                            <span className="text-zinc-700 text-sm font-normal">2. {languages.language === 'id' ?"Isikan data sesuai template yang tersedia ":"Fill in data according to the available template"}<br/>
                            3. {languages.language === 'id' ?"Pastikan data yang diisi benar":"Make sure the data  you fill is correct"}<br/>
                            4. {languages.language === 'id' ?"Setelah semua data diisi kemudian":"After all the data is filled in then"}</span>
                            <span className="text-zinc-700 text-sm font-bold">{languages.language === 'id' ?"‘Unggah File’":"‘Upload File’"} </span>
                            {languages.language === 'id' ?"dan pilih ":"and click "}
                            <span className="text-zinc-700 text-sm font-bold">{languages.language === 'id' ?"Submit":"Submit"} </span>
                        </p>
                    </div>

                    <div>
                        <h1 className="text-zinc-800 text-sm font-semibold mb-[8px]">{languages.language === 'id' ?"Unduh Template":"Download Template"}  :</h1>
                        <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <BiDownload  className='text-xl text-white'/>
                            <h1 className='text-dark-9 text-sm font-[500]'>Surat Tugas Perjalanan</h1>
                        </button>
                    </div> */}

                    <div className='flex gap-[21px] justify-between'>
                        <div className='w-full space-y-[21px]'>
                            <div className='text-grey-medium w-full'>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Jenis Perjalanan Dinas" : "Name of Trip"} <span className='text-red-900'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setWorkTravelType(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Jenis Perjalanan Dinas" : "Select Work Travel Type..."}</option>
                                            {Object.values(dataWorkTravelType).map((item, idx) => (
                                                <option key={idx} value={item.id}>{item.type_name}</option>
                                            ))}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>

                            <div className='text-grey-medium w-full'>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Tanggal Mulai" : "Start Date"}</h1>
                                <input type="date" onChange={(e) => setStartDate(e.target.value)} className='text-[10py] px-[12px] py-[10px] text-xs border rounded-md outline-none w-full' />
                            </div>
                            <div className='text-grey-medium w-full'>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Tanggal Selesai" : "End Date"}</h1>
                                <input type="date" onChange={(e) => setEndDate(e.target.value)} className='text-[10py] px-[12px] py-[10px] text-xs border rounded-md outline-none w-full' />
                            </div>
                            <div className='text-grey-medium w-full'>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Daerah Tujuan Perjalanan Dinas" : "Location"} <span className='text-red-900'>*</span></h1>
                                <input type="text" onChange={(e) => setLocation(e.target.value)} className='text-[10py] px-[12px] py-[10px] text-xs border rounded-md outline-none w-full' placeholder='Daerah Tujuan Perjalanan Dinas' />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='w-full space-y-[21px]'>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Surat Tugas Perjalanan Dinas" : "File"} <span className='text-red-900'>*</span></h1>
                                    <label htmlFor='upload-file' className='cursor-pointer'>
                                        <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                            <div className='flex items-center justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                    <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                </svg>
                                            </div>
                                            {namePath ? (
                                                <h1 className='text-gray-500 text-[12px] text-center'>{namePath}</h1>
                                            ) : (
                                                <>
                                                    <h1 className='text-red-primer text-[12px] font-medium text-center'>{languages.language === 'id' ? "Pilih File" : "Choose File"}</h1>
                                                    <div className="text-gray-500 text-[8px] text-center">pdf maks 2 MB</div>
                                                </>
                                            )}
                                        </div>
                                        <input type='file' id='upload-file' accept=".pdf" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                    </label>
                                </div>
                                <div className='text-grey-medium w-full'>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-1">Nomor Surat Tugas <span className='text-red-900'>*</span></h1>
                                    <input onChange={(e) => setNoSurat(e.target.value)} type="text" className='text-[10py] px-[12px] py-[10px] text-xs border rounded-md outline-none w-full' placeholder='Input Nomor Surat Tugas...' />
                                </div>
                                <div className='text-grey-medium w-full'>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-1">{languages.language === 'id' ? "Maksud Perjalanan Dinas" : "Purpose"} <span className='text-red-900'>*</span></h1>
                                    <input onChange={(e) => setPurpose(e.target.value)} type="text" className='text-[10py] px-[12px] py-[10px] text-xs border rounded-md outline-none w-full' placeholder={languages.language === 'id' ? "Input Maksud Perjalanan Dinas" : "Goal..."} />
                                </div>
                                {/* <div>
                                        <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatanganan" : "Approval"} <span className='text-[#780000]'>*</span></h1>

                                        <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                                            <BiSearch className='absolute right-[12px] text-lg' />
                                            <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder={languages.language === 'id' ? "Cari..." : "Search Employee name..."} className='outline-none text-[12px] w-full' />
                                        </div>

                                        {searchTerm.trim() !== '' && (
                                            <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                                {dataEmployeeAll.map((item, index) => {
                                                    if (item.role === 'USER') {
                                                        return (
                                                            <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.id)}>
                                                                {item.fullname}
                                                            </h1>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )}

                                        {employeeName.length > 0 && (
                                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                {employeeName.map((name, index) => (
                                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                                            <h1>{name}</h1>
                                                            <button onClick={() => handleRemoveClickedEmployee(name)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-end'>
                        <button onClick={createOfficialWorkTravel} className='py-[10px] px-[30px] bg-blue-primer rounded-lg text-white'>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportDataWorkTravel