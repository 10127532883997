import React from 'react'
import PrimaryButton from '../components/Button/PrimaryButton'
import IconPrimaryButton from '../components/Button/IconPrimaryButton'
import { IconDownload } from '../assets'
import SecondaryButton from '../components/Button/SecondaryButton'

const Test = () => {
    return (
        <div className='w-screen h-screen p-11'>
            <IconPrimaryButton icon={IconDownload} text={'download template'} />
            <SecondaryButton text={"seondary"} />
        </div>
    )
}

export default Test
