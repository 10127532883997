import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useLocation } from 'react-router-dom'
import Api from '../../../../Api'
import { HeaderProfile, LogoH } from '../../../../assets'
import { Modal, Navbar } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import Dependents from './components/Dependents'
import EmergencyContact from './components/EmergencyContact'
import File from './components/File'
import Imigration from './components/Imigration'
import JobDetails from './components/JobDetails'
import Payroll from './components/Payroll'
import Penghargaan from './components/Penghargaan'
import PersonalDetail from './components/PersonalDetail'
import Qualifications from './components/Qualifications'
import ReportTo from './components/ReportTo'
import Training from './components/Training'
import TugasBelajar from './components/TugasBelajar'

const MyProfile = () => {
    const [index, setIndex] = useState(0)

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState('');

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '6px',
            border: '1px solid #A8A8A8',
            fontSize: '12px',
            paddingLeft: '2px',
            width: '100%',
            color: '#A8A8A8',
        }),
    };

    //Emergency Contact Modal
    const [showAddEmergencyContact, setShowAddEmergencyContact] = useState()
    const [showEditEmergencyContact, setShowEditEmergencyContact] = useState()
    //Dependents Modal
    const [showAddDependents, setShowAddDependents] = useState()
    const [showEditDependents, setShowEditDependents] = useState()
    //Imigration Modal
    const [showAddImigration, setShowAddImigration] = useState()
    const [showEditImigration, setShowEditImigration] = useState()
    //Report-to Modal
    const [showAddSupervisor, setShowAddSupervisor] = useState()
    const [showEditSupervisor, setShowEditSupervisor] = useState()
    const [showAddSubordinate, setShowAddSubordinate] = useState()
    const [showEditSubordinate, setShowEditSubordinate] = useState()
    //Qualification Modal
    const [showAddWorkExperience, setShowAddWorkExperience] = useState()
    const [showEditWorkExperience, setShowEditWorkExperience] = useState()
    const [showAddEducation, setShowAddEducation] = useState()
    const [showEditEducation, setShowEditEducation] = useState()
    const [showAddSkills, setShowAddSkills] = useState()
    const [showEditSkills, setShowEditSkills] = useState()
    const [showAddLanguages, setShowAddLanguages] = useState()
    const [showEditLanguages, setShowEditLanguages] = useState()
    const [showAddLicense, setShowAddLicense] = useState()
    const [showEditLicense, setShowEditLicense] = useState()

    const params = useLocation()
    const [userId, setUserId] = useState('')

    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    // State Personal Detail
    const [namePersonalDetail, setNamePersonalDetail] = useState('')
    const [imagePersonalDetail, setImagePersonalDetail] = useState('')
    const [jobPositionPersonalDetail, setJobPositionPersonalDetail] = useState('')

    const getDataPersonalDetail = async () => {
        try {
            if (!params.state) {
                const response = await Api.GetPersonalDetail(localStorage.getItem('hris-token'))
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
                console.log(response.data, 'gerPerDet')

            } else {
                const response = await Api.GetPersonalDetailById(localStorage.getItem('hris-token'), params.state.employeeId)
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Emergency Contact
    const [nameEmergencyContact, setNameEmergencyContact] = useState()
    const [telephoneEmergencyContact, setTelephoneEmergencyContact] = useState()
    const [mobileEmergencyContact, setMobileEmergencyContact] = useState()
    const [addresEmergencyContact, setAddressEmergencyContact] = useState()
    const [relationshipEmergencyContact, setRelationshipEmergencyContact] = useState()
    const [idEmergencyContact, setIdEmergencyContact] = useState()

    const resetFormEmergencyContact = () => {
        setNameEmergencyContact('')
        setTelephoneEmergencyContact('')
        setMobileEmergencyContact('')
        setAddressEmergencyContact('')
        setRelationshipEmergencyContact('')
        setIdEmergencyContact('')
    }

    const editEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                // telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                address: addresEmergencyContact,
                relationship: relationshipEmergencyContact
            }
            await Api.UpdateEmergencyContact(localStorage.getItem('hris-token'), data, idEmergencyContact).then((res) => {
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Edit Emergency Contact')
                setShowEditEmergencyContact(!showEditEmergencyContact)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')

        }
    }

    const openEditEmergencyContact = async (id) => {
        setShowEditEmergencyContact(!showEditEmergencyContact)
        setIdEmergencyContact(id)
        try {
            const response = await Api.GetEmergencyContactById(localStorage.getItem('hris-token'), id)
            setNameEmergencyContact(response.data.name)
            setTelephoneEmergencyContact(response.data.telephone)
            setMobileEmergencyContact(response.data.mobile)
            setRelationshipEmergencyContact(response.data.relationship)
            setAddressEmergencyContact(response.data.address)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                // telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                address: addresEmergencyContact,
                relationship: relationshipEmergencyContact
            }
            if (!params.state) {
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, userId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            } else {
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, params.state.employeeId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    // Dependents
    const [nameDependents, setNameDependents] = useState()
    const [relationshipDependents, setRelationshipDependents] = useState()
    const [birthDateDependents, setBirthDateDependents] = useState()
    const [idDependents, setIdDependents] = useState()

    const resetFormDependents = () => {
        setNameDependents('')
        setRelationshipDependents('')
        setBirthDateDependents('')
        setIdDependents('')
    }

    const postDependents = async () => {
        try {
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            }
            if (!params.state) {
                await Api.PostDependents(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormDependents()
                    setRefresh(true)
                    toast.success('Success Create Dependents')
                    setShowAddDependents(!showAddDependents)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostDependents(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormDependents()
                    setRefresh(true)
                    toast.success('Success Create Dependents')
                    setShowAddDependents(!showAddDependents)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const openEditDependents = async (id) => {
        setShowEditDependents(!showEditDependents)
        setIdDependents(id)
        try {
            const response = await Api.GetDependentsById(localStorage.getItem('hris-token'), id)
            setNameDependents(response.data[0].name)
            setRelationshipDependents(response.data[0].relationship)
            setBirthDateDependents(response.data[0].birthday)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editDependents = async () => {
        try {
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            }
            await Api.UpdateDependents(localStorage.getItem('hris-token'), data, idDependents).then((res) => {
                resetFormDependents()
                setRefresh(true)
                toast.success('Success Edit Dependents')
                setShowEditDependents(!showEditDependents)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Error Edit Dependents')
        }
    }



    // Imigration
    const [documentType, setDocumentType] = useState()
    const [number, setNumber] = useState()
    const [issueDateImmigration, setIssueDateImmigration] = useState()
    const [expiryDateImmigration, setExpiryDateImmigration] = useState()
    const [eligibleStatus, setEligibleStatus] = useState()
    const [issuedby, setIssuedby] = useState()
    const [eligileIssueDate, setEligileIssueDate] = useState()
    const [comment, setComment] = useState()
    const [idImmigration, setIdImmigration] = useState()

    const resetFormImigration = () => {
        setDocumentType('')
        setNumber('')
        setIssueDateImmigration('')
        setExpiryDateImmigration('')
        setEligibleStatus('')
        setIssuedby('')
        setEligileIssueDate('')
        setComment('')
        setIdImmigration('')
    }

    const postImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            if (!params.state) {
                await Api.PostImmigration(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormImigration()
                    setRefresh(true)
                    toast.success('Success Create Immigration')
                    setShowAddImigration(!showAddImigration)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostImmigration(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormImigration()
                    setRefresh(true)
                    toast.success('Success Create Immigration')
                    setShowAddImigration(!showAddImigration)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create Immigration')
        }
    }

    const openEditImirgration = async (id) => {
        setShowEditImigration(!showEditImigration)
        setIdImmigration(id)
        try {
            const response = await Api.GetImmigrationById(localStorage.getItem('hris-token'), id)
            setDocumentType(response.data[0].documentType)
            setNumber(response.data[0].number)
            setIssueDateImmigration(response.data[0].issueDate)
            setExpiryDateImmigration(response.data[0].expiryDate)
            setEligibleStatus(response.data[0].eligibleStatus)
            setIssuedby(response.data[0].issuedby)
            setEligileIssueDate(response.data[0].eligileIssueDate)
            setComment(response.data[0].note)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            await Api.UpdateImmigration(localStorage.getItem('hris-token'), data, idImmigration).then((res) => {
                resetFormImigration()
                setRefresh(true)
                toast.success('Success Edit Immigration')
                setShowEditImigration(!showEditImigration)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Success Edit Immigration')
        }
    }

    // Report To 
    const [nameSupervisor, setNameSupervisor] = useState('')
    const [reportingMethodSupervisor, setReportingMethodSupervisor] = useState('')
    const [nameSubordinate, setNameSubordinate] = useState('')
    const [reportingMethodSubordinate, setReportingMethodSubordinate] = useState('')
    const [idReportToSupervisor, setIdReportSupervisor] = useState()
    const [refresh, setRefresh] = useState(false)

    const resetFormReportTo = () => {
        setNameSupervisor('')
        setReportingMethodSupervisor('')
    }

    const openEditReportToSupervisor = async (id) => {
        setShowEditSupervisor(!showEditSupervisor)
        setIdReportSupervisor(id)
        try {
            const response = await Api.GetReportToSupervisorById(localStorage.getItem('hris-token'), id)
            setNameSupervisor(response.data.name)
            setReportingMethodSupervisor(response.data.report_method.name)
        } catch (error) {
            console.log(error)
        }
    }

    const editSupervisor = async () => {
        try {
            const data = {
                name: nameSupervisor,
                report_method: reportingMethodSupervisor
            }
            await Api.UpdateReportToSupervisor(localStorage.getItem('hris-token'), data, idReportToSupervisor).then((res) => {
                resetFormReportTo()
                setRefresh(true)
                toast.success('Success Edit')
                setShowEditSupervisor(!showEditSupervisor)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Edit')
        }
    }

    // const getReportMethod = async () => {
    //     try {
    //         if (!params.state) {
    //             const response = await Api.GetReportMethod(localStorage.getItem('hris-token'))
    //             setDataReportMethod(response.data)
    //         } else {
    //             const response = await Api.GetReportMethodByParam(localStorage.getItem('hris-token'), params.state.employeeId)
    //             setDataReportMethod(response.data)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // Qualification
    const [companyNameWorkExperience, setCompanyNameWorkExperience] = useState('')
    const [jobTittleeWorkExperience, setJobTittleeWorkExperience] = useState('')
    const [startDateWorkExperience, setStartDateWorkExperience] = useState('')
    const [endDateWorkExperience, setEndDateWorkExperience] = useState('')
    const [commentWorkExperience, setCommentWorkExperience] = useState('')

    const [nameSkill, setNameSkill] = useState('')
    const [yearExperienceSkill, setYearExperienceSkill] = useState('')
    const [commentSkill, setCommentSkill] = useState('')

    const [educationLevel, setEducationLevel] = useState('')
    const [institute, setInstitute] = useState('')
    const [major, setMajor] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [gpa, setGpa] = useState('')

    const [languages, setLanguages] = useState('')
    const [fluency, setFluency] = useState('')
    const [competency, setCompetency] = useState('')
    const [commentLanguages, setCommentLanguages] = useState('')

    const [licenseType, setLicenseType] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('')
    const [issuedDate, setIssuedDate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')

    const [idWorkExperience, setIdWorkExperience] = useState('')
    const [idWorkEducation, setIdWorkEducation] = useState('')
    const [idWorkSkill, setIdWorkSkill] = useState('')
    const [idWorkLisence, setIdWorkLisence] = useState('')
    const [idWWorkLanguage, setIdWorkLanguage] = useState('')

    const resetFormQualification = () => {
        setCompanyNameWorkExperience('')
        setJobTittleeWorkExperience('')
        setStartDateWorkExperience('')
        setEndDateWorkExperience('')
        setCommentWorkExperience('')
        setNameSkill('')
        setYearExperienceSkill('')
        setCommentSkill('')
        setEducationLevel('')
        setInstitute('')
        setMajor('')
        setStartYear('')
        setEndYear('')
        setGpa('')
        setLanguages('')
        setFluency('')
        setCompetency('')
        setCommentLanguages('')
        setLicenseType('')
        setLicenseNumber('')
        setIssuedDate('')
        setExpiryDate('')
        setIdWorkExperience('')
        setIdWorkEducation('')
        setIdWorkSkill('')
        setIdWorkLanguage('')
    }

    const postWorkExperience = async () => {
        try {
            const data = {
                companyName: companyNameWorkExperience,
                jobTitle: jobTittleeWorkExperience,
                comment: commentWorkExperience,
                startDate: startDateWorkExperience,
                endDate: endDateWorkExperience
            }
            if (!params.state) {
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, userId)
                console.log(response)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddWorkExperience(!showAddWorkExperience)
            } else {
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, params.state.employeeId)
                console.log(response)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddWorkExperience(!showAddWorkExperience)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkExperience = async () => {
        try {
            const data = {
                companyName: companyNameWorkExperience,
                jobTitle: jobTittleeWorkExperience,
                comment: commentWorkExperience,
                startDate: startDateWorkExperience,
                endDate: endDateWorkExperience
            }
            await Api.UpdateWorkExperience(localStorage.getItem('hris-token'), data, idWorkExperience).then((res) => {
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Update !')
                setShowEditWorkExperience(!showEditWorkExperience)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const openEditWorkExperience = async (id) => {
        setShowEditWorkExperience(!showEditWorkExperience)
        setIdWorkExperience(id)
        try {
            const response = await Api.GetWorkExperienceById(localStorage.getItem('hris-token'), id)
            setCompanyNameWorkExperience(response.data[0].companyName)
            setJobTittleeWorkExperience(response.data[0].jobTitle)
            setStartDateWorkExperience(response.data[0].startDate)
            setEndDateWorkExperience(response.data[0].endDate)
            setCommentWorkExperience(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditSkill = async (id) => {
        setShowEditSkills(!showEditSkills)
        setIdWorkSkill(id)
        try {
            const response = await Api.GetWorkSkillById(localStorage.getItem('hris-token'), id)
            setNameSkill(response.data[0].skill)
            setYearExperienceSkill(response.data[0].yearsOfExperience)
            setCommentSkill(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkEducation = async (id) => {
        setShowEditEducation(!showEditEducation)
        setIdWorkEducation(id)
        try {
            const response = await Api.GetWorkEducationById(localStorage.getItem('hris-token'), id)
            setEducationLevel(response.data[0].level)
            setInstitute(response.data[0].institute)
            setMajor(response.data[0].major)
            setStartYear(moment(response.data[0].startDate).format('YYYY'))
            setEndYear(moment(response.data[0].endDate).format('YYYY'))
            setGpa(response.data[0].gap)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkLanguage = async (id) => {
        setShowEditLanguages(!showEditLanguages)
        setIdWorkLanguage(id)
        try {
            const response = await Api.GetWorkLanguageById(localStorage.getItem('hris-token'), id)
            setLanguages(response.data[0].language)
            setFluency(response.data[0].fluency)
            setCompetency(response.data[0].competency)
            setCommentLanguages(response.data[0].language)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkLicense = async (id) => {
        setShowEditLicense(!showEditLicense)
        setIdWorkLisence(id)
        try {
            const response = await Api.GetWorkLicenseById(localStorage.getItem('hris-token'), id)
            setLicenseType(response.data[0].licenseType)
            setLicenseNumber(response.data[0].licenseNumber)
            setIssuedDate(response.data[0].issuedDate)
            setExpiryDate(response.data[0].expiryDate)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postWorkEducation = async () => {
        try {
            if (educationLevel === '' || educationLevel === null) {
                toast.error("Tingkat pendidikan wajib diisi!")
            } else if (startYear === '' || startYear === null) {
                toast.error("Tahun Mulai wajib diisi!")
            } else if (endYear === '' || endYear === null) {
                toast.error("Tahun Akhir wajib diisi!")
            } else if (gpa === '' || gpa === null) {
                toast.error("Nilai Akhir wajib diisi!")
            } else {
                const data = {
                    level: educationLevel,
                    institute: institute,
                    major: major,
                    start_year: startYear,
                    end_year: endYear,
                    gap: gpa,
                }
                if (!params.state) {
                    await Api.PostWorkEducation(localStorage.getItem('hris-token'), data, userId).then((res) => {
                        resetFormQualification()
                        setRefresh(true)
                        toast.success('Berhasil menambahkan data!')
                        setShowAddEducation(!showAddEducation)
                    }).catch((err) => {
                        console.log(err)
                    })
                } else {
                    await Api.PostWorkEducation(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                        resetFormQualification()
                        setRefresh(true)
                        toast.success('Berhasil menambahkan data!')
                        setShowAddEducation(!showAddEducation)
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            }
        } catch (error) {
            console.log(error)
            toast.error('Gagal menambahkan data!')
        }
    }

    const editWorkEducation = async () => {
        try {
            const data = {
                level: educationLevel,
                institute: institute,
                major: major,
                start_year: startYear,
                end_year: endYear,
                gap: gpa,
            }
            await Api.UpdateWorkEducation(localStorage.getItem('hris-token'), data, idWorkEducation).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditEducation(!showEditEducation)
                toast.success('Success Update !')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkSkill = async () => {
        try {
            const data = {
                skill: nameSkill,
                yearsOfExperience: yearExperienceSkill,
                comment: commentSkill
            }

            if (!params.state) {

                await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddSkills(!showAddSkills)
                }).catch((err) => {
                    console.log(err)
                })
            } else {

                await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddSkills(!showAddSkills)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkSkill = async () => {
        try {
            const data = {
                skill: nameSkill,
                yearsOfExperience: yearExperienceSkill,
                comment: commentSkill
            }

            await Api.UpdateWorkSkill(localStorage.getItem('hris-token'), data, idWorkSkill).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditSkills(!showEditSkills)
                toast.success('Success Update !')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkLanguage = async () => {
        try {
            const data = {
                language: languages,
                fluency: fluency,
                competency: competency,
                comment: commentLanguages
            }
            if (!params.state) {
                await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddLanguages(!showAddLanguages)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddLanguages(!showAddLanguages)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkLanguage = async () => {
        try {
            const data = {
                language: languages,
                fluency: fluency,
                competency: competency,
                comment: commentLanguages
            }
            await Api.UpdateWorkLanguage(localStorage.getItem('hris-token'), data, idWWorkLanguage).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditLanguages(!showEditLanguages)
                toast.success('Success Update !')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkLicense = async () => {
        try {
            const data = {
                licenseType: licenseType,
                licenseNumber: licenseNumber,
                issuedDate: issuedDate,
                expiryDate: expiryDate
            }
            if (!params.state) {
                await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddLicense(!showAddLicense)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormQualification()
                    setRefresh(true)
                    toast.success('Success Create!')
                    setShowAddLicense(!showAddLicense)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkLisence = async () => {
        try {
            const data = {
                licenseType: licenseType,
                licenseNumber: licenseNumber,
                issuedDate: issuedDate,
                expiryDate: expiryDate
            }
            await Api.UpdateWorkLicense(localStorage.getItem('hris-token'), data, idWorkLisence).then((res) => {
                resetFormQualification()
                setRefresh(true)
                setShowEditLicense(!showEditLicense)
                toast.success('Success Update !')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const [viewImage, setViewImage] = useState()

    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('Gambar tidak boleh lebih dari 2 MB !')
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('File harus bertipe jpg, jpeg, atau png !')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                updateProfilePersonalDetail(reader.result)
            };
        }
    }

    const updateProfilePersonalDetail = async (imageUploaded) => {
        try {
            const data = {
                image: imageUploaded
            }
            if (!params.state) {
                console.log(data)
                await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    toast.success('Success Update Gambar')
                    setRefresh(true)
                    console.log(res)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                console.log(data)
                await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    toast.success('Success Update Gambar')
                    setRefresh(true)
                    console.log(res)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    // const getAllEmployee = async () => {
    //     try {
    //         await Api.getAllEmployee(localStorage.getItem('hris-token')).then((res)=>{
    //         const res = response.data.data

    //         const options = res && res.map(val => ({
    //             "label": val.fullname,
    //             "value": val.id,
    //         }))

    //         setSelectedEmployee(options)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
        const selectedValue = selectedOption.value;
        setSelectedOptionId(selectedValue)
    };

    const postReportToSupervisor = async () => {
        try {
            const data = {
                reportToEmployeeId: selectedOptionId,
                report_method: reportingMethodSupervisor
            }
            console.log(data)
            if (!params.state) {
                await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, userId).then((res) => {
                    resetFormReportTo()
                    toast.success('Success Create')
                    setRefresh(true)
                    setShowAddSupervisor(!showAddSupervisor)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, params.state.employeeId).then((res) => {
                    resetFormReportTo()
                    setRefresh(true)
                    toast.error('Failed Create')
                    setShowAddSupervisor(!showAddSupervisor)
                }).catch((err) => {
                    console.log(err)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        Fetch()
        getDataPersonalDetail()
        // getReportMethod()
        setRefresh(false)
    }, [refresh])

    return (
        <div>
            {/* Modal */}
            <div>
                <Modal
                    activeModal={showAddDependents}
                    title={'Tambah Tanggungan'}
                    buttonClose={() => setShowAddDependents(!showAddDependents)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameDependents(e.target.value)} value={nameDependents} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Input Nama...' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Hubungan</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setRelationshipDependents(e.target.value)} value={relationshipDependents} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected disabled={''}>Pilih Hubungan</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Lainnya'}>Lainnya</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tanggal Lahir</h2>
                                    <input onChange={(e) => setBirthDateDependents(e.target.value)} value={birthDateDependents} type="date" className='border-dark-7 pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border' />
                                </div>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => setShowAddDependents(!showAddDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postDependents} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditDependents}
                    title={'Edit Tanggungan'}
                    buttonClose={() => setShowEditDependents(!showEditDependents)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input value={nameDependents} onChange={(e) => setNameDependents(e.target.value)} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Dependents name...' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Hubungan</h2>
                                    <div className='relative'>
                                        <select value={relationshipDependents} onChange={(e) => setRelationshipDependents(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Pilih Hubungan</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Lainnya'}>Lainnya</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tanggal Lahir</h2>
                                <input onChange={(e) => setBirthDateDependents(e.target.value)} value={moment(birthDateDependents).format('YYYY-MM-DD')} type="date" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px]' />
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end ">
                                <button onClick={() => setShowEditDependents(!showEditDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editDependents} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showAddEmergencyContact}
                    title={'Tambah Kontak Darurat'}
                    buttonClose={() => setShowAddEmergencyContact(!showAddEmergencyContact)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px]'>
                            <div className="flex gap-[14px]">
                                <div className='w-full mb-[20px]'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Kontak Keluarga <span className='text-red-primer'>*</span></h1>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setRelationshipEmergencyContact(e.target.value)} value={relationshipEmergencyContact} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Kontak Keluarga...</option>
                                            <option value={'Ayah'}>Ayah</option>
                                            <option value={'Ibu'}>Ibu</option>
                                            <option value={'Suami'}>Suami</option>
                                            <option value={'Istri'}>Istri</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Saudara'}>Saudara</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameEmergencyContact(e.target.value)} value={nameEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input Nama...' />
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>No. Handphone</h1>
                                    <input onChange={(e) => setMobileEmergencyContact(e.target.value)} value={mobileEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input No. Handphone...' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5'>Alamat</h1>
                                <textarea rows={2} onChange={(e) => setAddressEmergencyContact(e.target.value)} value={addresEmergencyContact} className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 resize-none' placeholder='Input Alamat...' />
                            </div>

                            <div className="flex gap-[10px] justify-end ">
                                <button onClick={() => setShowAddEmergencyContact(!showAddEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postEmergencyContact} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditEmergencyContact}
                    title={'Edit Kontak Darurat'}
                    buttonClose={() => setShowEditEmergencyContact(!showEditEmergencyContact)}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px]'>
                            <div className="flex gap-[14px]">
                                <div className='w-full mb-[20px]'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Kontak Keluarga <span className='text-red-primer'>*</span></h1>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setRelationshipEmergencyContact(e.target.value)} value={relationshipEmergencyContact} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Kontak Keluarga...</option>
                                            <option value={'Ayah'}>Ayah</option>
                                            <option value={'Ibu'}>Ibu</option>
                                            <option value={'Suami'}>Suami</option>
                                            <option value={'Istri'}>Istri</option>
                                            <option value={'Anak'}>Anak</option>
                                            <option value={'Saudara'}>Saudara</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama</h1>
                                    <input onChange={(e) => setNameEmergencyContact(e.target.value)} value={nameEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input Nama...' />
                                </div>
                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>No. Handphone</h1>
                                    <input onChange={(e) => setMobileEmergencyContact(e.target.value)} value={mobileEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5' placeholder='Input No. Handphone...' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5'>Alamat</h1>
                                <textarea rows={2} onChange={(e) => setAddressEmergencyContact(e.target.value)} value={addresEmergencyContact} className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 resize-none' placeholder='Input Alamat...' />
                            </div>

                            <div className="flex gap-[10px] justify-end ">
                                <button onClick={() => setShowEditEmergencyContact(!showEditEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editEmergencyContact} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddImigration}
                    title={'Add Migration'}
                    buttonClose={() => setShowAddImigration(!showAddImigration)}
                    width={'558px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Document</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setDocumentType(e.target.value)} value={documentType} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Document</option>
                                            <option value={'Visa'}>Visa</option>
                                            <option value={'Passport'}>Passport</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Number</h2>
                                    <input onChange={(e) => setNumber(e.target.value)} value={number} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border' placeholder='Document Number...' />
                                </div>
                            </div>
                            <div className='flex gap-[44px] w-full'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued Date</h2>
                                    <input onChange={(e) => setIssueDateImmigration(e.target.value)} value={issueDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Expiry Date</h2>
                                    <input onChange={(e) => setExpiryDateImmigration(e.target.value)} value={expiryDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className='flex gap-[44px] w-full'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued by</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setIssuedby(e.target.value)} value={issuedby} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Country</option>
                                            <option value={'Indonesia'}>Indonesia</option>
                                            <option value={'Inggris'}>Inggris</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-dark-5 text-[12px] mb-[12px]'>Note</h2>
                                <textarea onChange={(e) => setComment(e.target.value)} type="date" value={comment} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4} />
                            </div>
                            <div className="flex gap-[10px] items-end justify-end">
                                <button onClick={() => setShowAddImigration(!showAddImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postImmigration} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditImigration}
                    title={'Edit Migration'}
                    buttonClose={() => setShowEditImigration(!showEditImigration)}
                    width={'558px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Document</h2>
                                    <div className='relative'>
                                        <select value={documentType} onChange={(e) => setDocumentType(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Document</option>
                                            <option value={'Visa'}>Visa</option>
                                            <option value={'Passport'}>Passport</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Number</h2>
                                    <input value={number} onChange={(e) => setNumber(e.target.value)} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border' placeholder='Document Number...' />
                                </div>
                            </div>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued Date</h2>
                                    <input value={moment(issueDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setIssueDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Expiry Date</h2>
                                    <input value={moment(expiryDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setExpiryDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className='flex gap-[44px]'>
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] mb-[12px]'>Issued by</h2>
                                    <div className='relative'>
                                        <select value={issuedby} onChange={(e) => setIssuedby(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Country</option>
                                            <option value={'Indonesia'}>Indonesia</option>
                                            <option value={'English'}>English</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-dark-5 text-[12px] mb-[12px]'>Note</h2>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-dark-5 w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4} />
                            </div>
                            <div className="flex gap-[10px] items-end justify-end">
                                <button onClick={() => setShowEditImigration(!setShowEditImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editImmigration} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Save</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSupervisor}
                    title={'Tambah Atasan'}
                    buttonClose={() => setShowAddSupervisor(!showAddSupervisor)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            {/* <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <div className='relative'>
                                        <select onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} type="text" className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option>Pilih Nama</option>
                                            {Object.values(selectedEmployee).map((data, index) => {
                                                console.log(data)
                                                return (
                                                    <option key={index} value={data.value}>{data.label}</option>
                                                    )
                                                })}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div> */}

                            <h1 className='text-[12px] text-dark-5'>Nama <span className='text-red-primer'> *</span></h1>
                            {/* <Select
                                className="basic-single"
                                classNamePrefix="Pilih Nama"
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                value={selectedOption}
                                onChange={handleSelectChange}
                                name="atasan"
                                options={selectedEmployee}
                                placeholder="Pilih Atasan"
                                styles={customStyles}
                            /> */}
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSupervisor(!showAddSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button onClick={postReportToSupervisor} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSupervisor}
                    title={'Edit Atasan'}
                    buttonClose={() => setShowEditSupervisor(!showEditSupervisor)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div className='w-full'>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <input type="text" onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Structure ID <span className='text-red-primer'>*</span><h1/>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]'/>
                            </div> */}

                            </div>
                            <div className="flex gap-[14px]">
                                <div className='w-full'>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSupervisor(!showEditSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button onClick={editSupervisor} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSubordinate}
                    title={'Tambah Bawahan'}
                    buttonClose={() => setShowAddSubordinate(!showAddSubordinate)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama  <span className='text-red-primer'> *</span></h1>
                                    <input onChange={(e) => setNameSubordinate(e.target.value)} value={nameSubordinate} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Struktur ID  <span className='text-red-primer'> *</span></h1>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div> */}
                            </div>
                            <div className="flex gap-[14px]">
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select onChange={(e) => setReportingMethodSubordinate(e.target.value)} value={reportingMethodSubordinate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[13px] py-[10px] w-[252px] appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSubordinate(!showAddSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSubordinate}
                    title={'Edit Bawahan'}
                    buttonClose={() => setShowEditSubordinate(!showEditSubordinate)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className="flex  gap-[14px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama <span className='text-red-primer'> *</span></h1>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' placeholder='Pilih Nama' />
                                </div>
                                {/* <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Structure ID <span className='text-red-primer'>*</span><h1/>
                                    <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div> */}

                            </div>
                            <div className="flex gap-[14px]">
                                <div>
                                    <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Metode Report-To <span className='text-red-primer'> *</span></h2>
                                    <div className='relative'>
                                        <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[252px] appearance-none'>
                                            <option selected value={''}>Metode Report-To</option>
                                            <option value={'Direct'}>Direct</option>
                                            <option value={'In-Direct'}>In-Direct</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSubordinate(!showEditSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Cancel</h1>
                                    </button>
                                    <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Submit</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showAddEducation}
                    title={'Tambah Pendidikan'}
                    buttonClose={() => setShowAddEducation(!showAddEducation)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tingkat Pendidikan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setEducationLevel(e.target.value)} value={educationLevel} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Masukkan Tingkat Pendidikan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Institusi Pendidikan</h1>
                                <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Masukkan Institusi Pendidikan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Keahlian/Spesialis</h1>
                                <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Masukkan Keahlian/Spesialis...' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tahun Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartYear(e.target.value)} value={startYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tahun Akhir <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndYear(e.target.value)} value={endYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nilai Akhir <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" min={0} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-1/2' placeholder='Masukkan Nilai Akhir' />
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddEducation(!showAddEducation)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkEducation} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditEducation}
                    title={'Edit Pendidikan'}
                    buttonClose={() => setShowEditEducation(!showEditEducation)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tingkat Pendidikan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setEducationLevel(e.target.value)} value={educationLevel} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Ex : Senior High School' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Institusi Pendidikan</h1>
                                <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Masukkan Institusi Pendidikan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Keahlian/Spesialis</h1>
                                <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Masukkan Keahlian/Spesialis....' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tahun Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartYear(e.target.value)} value={moment(startYear).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Tahun Akhir <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndYear(e.target.value)} value={moment(endYear).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nilai AKhir <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-1/2' placeholder='Masukkan Nilai Akhir...' />
                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditEducation(!showEditEducation)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkEducation} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddWorkExperience}
                    title={'Tambah Pengalaman Kerja'}
                    buttonClose={() => setShowAddWorkExperience(!showAddWorkExperience)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Perusahaan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Perusahaan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Jabatan*</h1>
                                <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Jabatan...' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={startDateWorkExperience} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndDateWorkExperience(e.target.value)} type="date" value={endDateWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddWorkExperience(!showAddWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkExperience} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditWorkExperience}
                    title={'Edit Pengalaman Kerja'}
                    buttonClose={() => setShowEditWorkExperience(!showEditWorkExperience)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Perusahaan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Perusahaan...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Jabatan <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Jabatan...' />
                            </div>
                            <div className="flex gap-[25px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={moment(startDateWorkExperience).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setEndDateWorkExperience(e.target.value)} value={moment(endDateWorkExperience).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditWorkExperience(!showEditWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkExperience} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddSkills}
                    title={'Tambah Skill'}
                    buttonClose={() => setShowAddSkills(!showAddSkills)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Skill <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Skill...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tahun Pengalaman</h1>
                                <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tahun Pengalaman...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddSkills(!showAddSkills)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkSkill} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditSkills}
                    title={'Edit Skill'}
                    buttonClose={() => setShowEditSkills(!showEditSkills)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nama Skill <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Skill...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tahun Pengalaman</h1>
                                <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tahun Pengalaman...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditSkills(!showEditSkills)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkSkill} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddLanguages}
                    title={'Tambah Bahasa'}
                    buttonClose={() => setShowAddLanguages(!showAddLanguages)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Bahasa <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Bahasa...' />
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kelancaran *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setFluency(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kelancaran...</option>
                                        <option value="Writing">Writing </option>
                                        <option value="Speaking">Speaking </option>
                                        <option value="Reading">Reading  </option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kompetensi *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kompetensi...</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Good">Good</option>
                                        <option value="Mother Tongue">Mother Tongue</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setCommentLanguages(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} placeholder='Deskripsi...' />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddLanguages(!showAddLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkLanguage} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditLanguages}
                    title={'Edit Bahasa'}
                    buttonClose={() => setShowEditLanguages(!showEditLanguages)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Bahasa <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Bahasa...' />
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kelancaran *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setEndYear(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kelancaran...</option>
                                        <option value="Writing">Writing </option>
                                        <option value="Speaking">Speaking </option>
                                        <option value="Reading">Reading  </option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h2 className='text-dark-5 text-[12px] font-medium mb-[9px]'>Kompetensi *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                        <option selected value={''}>Pilih Kompetensi...</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Good">Good</option>
                                        <option value="Mother Tongue">Mother Tongue</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Deskripsi</h1>
                                <textarea onChange={(e) => setComment(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full resize-none' rows={3} />
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditLanguages(!showEditLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]' placeholder='Deskripsi...'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkLanguage} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showAddLicense}
                    title={'Tambah Lisensi'}
                    buttonClose={() => setShowAddLicense(!showAddLicense)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tipe Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tipe Lisensi...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nomor Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nomor Lisensi...' />
                            </div>
                            <div className="flex gap-[32px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Terbit <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setIssuedDate(e.target.value)} value={issuedDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Kadaluarsa </h1>
                                    <input onChange={(e) => setExpiryDate(e.target.value)} value={expiryDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowAddLicense(!showAddLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postWorkLicense} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditLicense}
                    title={'Edit Lisensi'}
                    buttonClose={() => setShowEditLicense(!showEditLicense)}
                    width={'615px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tipe Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Tipe Lisensi...' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-dark-5 mb-[9px]'>Nomor Lisensi <span className='text-red-primer'>*</span></h1>
                                <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nomor Lisensi...' />
                            </div>
                            <div className="flex gap-[32px]">
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Terbit <span className='text-red-primer'>*</span></h1>
                                    <input onChange={(e) => setIssuedDate(e.target.value)} value={moment(issuedDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                                <div>
                                    <h1 className='text-[12px] text-dark-5 mb-[9px]'>Tanggal Kadaluarsa </h1>
                                    <input onChange={(e) => setExpiryDate(e.target.value)} value={moment(expiryDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[252px]' />
                                </div>
                            </div>

                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => setShowEditLicense(!showEditLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={editWorkLisence} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Profile'} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-[#F8F9FB] px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='flex flex-col relative'>
                            <img src={HeaderProfile} className='h-[91px] w-full rounded-t-xl' alt='' />
                            <div className='pl-[185px] h-[91px] w-full rounded-b-xl bg-white'>
                                <h1 className='text-[20px] font-semibold text-dark-3'>{namePersonalDetail}</h1>
                                <p className='text-[11px] text-[#A8A8A8] font-semibold'>{jobPositionPersonalDetail ? jobPositionPersonalDetail : 'ADMIN'}</p>
                            </div>
                            <label htmlFor='upload-image'>
                                <div className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] bg-cover hover:bg-slate-500' style={{
                                    backgroundImage: `url(${imagePersonalDetail ? imageHandle(imagePersonalDetail) : LogoH})`,
                                    backgroundSize: 'cover', // Menambahkan properti backgroundSize untuk memastikan gambar penuh
                                    backgroundPosition: 'center', // Menambahkan properti backgroundPosition untuk mengatur posisi gambar
                                    width: '95px',
                                    height: '95px',
                                    borderRadius: '50%',
                                }}>
                                    {/* <img className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] object-cover hover:bg-transparent' src={imagePersonalDetail? imageHandle(imagePersonalDetail) : imageHandle(viewImage)} alt="Profile" /> */}
                                    <input type='file' id='upload-image' onChange={(e) => handleViewImage(e)} className='hidden' />
                                </div>
                            </label>
                        </div>
                        <div className="flex relative gap-10">
                            <div className='w-[267px] h-[762px] py-[18px]  shadow rounded-l-xl border-r-6 relative pl-[18px] bg-white'>

                                <button onClick={() => setIndex(0)} className={index === 0 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 0 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Informasi Pribadi
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(1)} className={index === 1 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 1 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Kontak Darurat
                                    </h1>

                                </button>

                                <button onClick={() => setIndex(2)} className={index === 2 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 2 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Tanggungan
                                    </h1>
                                </button>

                                {/* <button onClick={() => setIndex(3)} className={index === 3 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 3 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Imigrasi
                                    </h1>
                                </button> */}

                                <button onClick={() => setIndex(4)} className={index === 4 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 4 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Pekerjaan
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(5)} className={index === 5 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 5 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Penghasilan
                                    </h1>
                                </button>

                                {/* <button onClick={() => setIndex(6)} className={index === 6 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 6 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Unit Kerja Atasan Langsung
                                    </h1>
                                </button> */}

                                <button onClick={() => setIndex(7)} className={index === 7 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 7 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Kualifikasi
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(8)} className={index === 8 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 8 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        File
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(9)} className={index === 9 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 9 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Pelatihan
                                    </h1>
                                </button>
                                <button onClick={() => setIndex(10)} className={index === 10 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 10 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Penghargaan
                                    </h1>
                                </button>
                                <button onClick={() => setIndex(11)} className={index === 11 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                    <h1 className={index === 11 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                        Tugas Belajar
                                    </h1>
                                </button>
                            </div>

                            <div className="ml-auto w-full p-5 space-y-5 bg-white rounded-r-xl h-[762px] overflow-y-auto border shadow-md scrollbar-default">
                                {index === 0 ? (
                                    <PersonalDetail />
                                ) : index === 1 ? (
                                    <EmergencyContact
                                        modalAddContact={() => setShowAddEmergencyContact(!showEditEmergencyContact)}
                                        modalEditContact={openEditEmergencyContact}
                                        refreshPage={refresh} />

                                ) : index === 2 ? (
                                    <Dependents
                                        ModalAddDepents={() => setShowAddDependents(!showAddDependents)}
                                        ModalEditDepents={openEditDependents}
                                        refreshPage={refresh} />
                                ) : index === 3 ? (
                                    <Imigration
                                        modalAddImigration={() => setShowAddImigration(!showAddImigration)}
                                        modalEditImigration={openEditImirgration}
                                        refreshPage={refresh} />
                                ) : index === 4 ? (
                                    <JobDetails
                                    />
                                ) : index === 5 ? (
                                    <Payroll />
                                ) : index === 6 ? (
                                    <ReportTo
                                        modalAddSubordinate={() => setShowAddSubordinate(!showAddSubordinate)}
                                        modalEditSubordinate={() => setShowEditSubordinate(!showEditSubordinate)}
                                        modalAddSupervisor={() => setShowAddSupervisor(!showAddSupervisor)}
                                        modalEditSupervisor={openEditReportToSupervisor}
                                        refreshPage={refresh} />
                                ) : index === 7 ? (
                                    <Qualifications
                                        modalAddWorkExperience={() => setShowAddWorkExperience(!showAddWorkExperience)}
                                        modalEditWorkExperience={openEditWorkExperience}
                                        modalAddEducation={() => setShowAddEducation(!showAddEducation)}
                                        modalEditEducation={openEditWorkEducation}
                                        modalAddSkills={() => setShowAddSkills(!showAddSkills)}
                                        modalEditSkills={openEditSkill}
                                        modalAddLanguages={() => setShowAddLanguages(!showAddLanguages)}
                                        modalEditLanguages={openEditWorkLanguage}
                                        modalAddLicense={() => setShowAddLicense(!showAddLicense)}
                                        modalEditLicense={openEditWorkLicense}
                                        refreshPage={refresh}
                                    />
                                ) : index === 8 ? (
                                    <File
                                        UserId={userId}
                                    />
                                ) : index === 9 ? (
                                    <Training
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : index === 10 ? (
                                    <Penghargaan
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : index === 11 ? (
                                    <TugasBelajar
                                        refreshPage={refresh}
                                        UserId={userId}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile