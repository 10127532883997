import { saveAs } from 'file-saver'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiFilter, BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'
import { FiEye } from 'react-icons/fi'
import { TbFileExport } from 'react-icons/tb'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import * as XLSX from 'xlsx'
import Api from "../../../../Api"
import { LogoH } from '../../../../assets'
import { Modal, Navbar, Pagination } from '../../../../components'
import InputOption from '../../../../components/Input/inputOption'
import languages from '../../../../utils/languages'


const Attendance = () => {

    // My Attendance State
    const [showDetailAttendance, setShowDetailAttendance] = useState()
    const [detail, setDetail] = useState()
    const [refresh, setRefresh] = useState('')
    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well

        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        // const s = d.seconds();

        if (endTime && startTime) {
            if (h !== 0) {
                return `${h} Jam ${m} Menit`;
            } else {
                return `${m} Menit`;
            }
        } else {
            return "-";
        }
    };

    const customIcon = new L.icon({
        iconUrl: LogoH,
        iconSize: [17, 17],
    });

    const [data, setData] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const limit = 10


    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // const [locationNameCheckout, setLocationNameCheckout] = useState('');
    // const [locationNameCheckin, setLocationNameCheckin] = useState('');

    const [showFilter, setShowFilter] = useState(false)
    const [penempatan, setPenempatan] = useState("")
    const [unitKerja, setUnitKerja] = useState("")
    const [kepegawaian, setKepegawaian] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [fullname, setFullname] = useState("")
    const [isAsc, setIsAsc] = useState(true)

    const getRecordAttendance = async () => {
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), startDate, endDate, fullname, limit, currentPage, penempatan, unitKerja, isAsc, kepegawaian)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [dataKepegawaian, setDataKepegawaian] = useState([])
    const GetData = async () => {
        try {
            await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token')).then((response) => {
                const data = response.data.results.data
                const formattedData = data.map(item => ({ value: item.id, label: item.company_name }));
                setDataPenempatan(formattedData)
            })
            await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token')).then((response) => {
                const data = response?.data?.results?.data
                const formattedData = data.map(item => ({ value: item.name, label: item.name }));
                setDataUnitKerja(formattedData)
            })
            await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token')).then((response) => {
                const data = response?.data?.results?.data
                const formattedData = data.map(item => ({ value: item.id, label: item.category_name }));
                setDataKepegawaian(formattedData)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const exportKehadiran = async () => {
        // Sample data array
        const res = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), startDate, endDate, fullname, totalCount, 1, penempatan, unitKerja, isAsc, kepegawaian);
        const body = res.data.response
        // Define custom headers for each table
        const Headers = ['NIP', 'Nama Pegawai', 'Tanggal', 'Presensi Masuk', 'Lokasi Presensi Masuk', 'Presensi Keluar', 'Lokasi Presensi Keluar', 'Durasi', 'Status', 'Keterangan'];

        // Create modified data arrays with custom headers
        const data = body.map(({ nip, fullname, date, time_checkin, time_checkout, latitude_checkin, longtitude_checkin, latitude_checkout, longtitude_checkout, status, keterangan }) => ({
            'NIP': nip ?? "-",
            'Nama Pegawai': fullname ?? "-",
            'Tanggal': moment(date).format('DD/MM/YYYY'),
            'Presensi Masuk': time_checkin ? moment(time_checkin).format('HH:mm:ss') : '-',
            'Lokasi Presensi Masuk': latitude_checkin && longtitude_checkin ? latitude_checkin + ',' + longtitude_checkin : '-',
            'Presensi Keluar': time_checkout ? moment(time_checkout).format('HH:mm:ss') : '-',
            'Lokasi Presensi Keluar': latitude_checkout && longtitude_checkout ? latitude_checkout + ',' + longtitude_checkout : '-',
            'Durasi': time_checkin && time_checkout ? duration(time_checkin, time_checkout) : '-',
            'Status': status ? status.map((val) => `${val}, `).join('') : '-',
            'Keterangan': keterangan
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Absensi');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Rekap Kehadiran.xlsx');
    };

    const handleResetFilter = () => {
        setStartDate("")
        setEndDate("")
        setFullname("")
        setPenempatan("")
        setUnitKerja("")
        setKepegawaian("")
        setShowFilter(!showFilter)
        setRefresh(true)
        // window.location.reload()
    }

    useEffect(() => {
        getRecordAttendance()
        GetData()
        setRefresh(false)
    }, [fullname, isAsc, refresh])

    return (
        <>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <Modal
                    activeModal={showFilter}
                    buttonClose={() => setShowFilter(!showFilter)}
                    width={'550px'}
                    title={
                        <div className='flex gap-[27px] items-center'>
                            <BsFilter className='text-dark-1 text-2xl' />
                            <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                        </div>
                    }
                    content={
                        <div className='space-y-[20px]'>
                            <div className='grid grid-cols-2 gap-3'>
                                <InputOption data={dataPenempatan} label={'Penempatan'} placeholder={"Pilih Penempatan..."} value={penempatan} onChange={(e) => setPenempatan(e)} required={false} />
                                <InputOption data={dataUnitKerja} label={'Unit Kerja'} placeholder={"Pilih Unit Kerja..."} value={unitKerja} onChange={(e) => setUnitKerja(e)} required={false} />
                                <InputOption data={dataKepegawaian} label={'JenisKepegawain'} placeholder={"Pilih Jenis Kepegawaian..."} value={kepegawaian} onChange={(e) => setKepegawaian(e)} required={false} />
                                <div className='col-span-2'>
                                    <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Tanggal</h1>
                                    <div className='flex gap-3 items-center'>
                                        <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>-</h1>
                                        <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between'>
                                <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-blue-thick text-[14px] font-medium flex justify-center rounded-md items-center border-2'>Reset Filter</button>
                                <button onClick={() => { setShowFilter(!showFilter); setRefresh(true) }} className='py-[10px] px-[15px] bg-blue-thick text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                            </div>
                        </div>
                    }
                />

                <Modal
                    activeModal={showDetailAttendance}
                    title={languages.language === 'id' ? "Detail Kehadiran" : "Detail Attendance"}
                    buttonClose={() => setShowDetailAttendance(!showDetailAttendance)}
                    width={'715px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <>
                                <div className='bg-[#F8F8F8] w-[623px] rounded-2xl mb-[30px]'>
                                    <div className='py-[20px] px-[20px]'>
                                        <h1 className=' text-dark-4 text-[13px] font-semibold mb-[22px]'>{languages.language === 'id' ? "Informasi Kehadiran" : "Attendance Information"}</h1>
                                        <div className='text-[11px] text-dark-5 font-semibold space-y-2'>
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-2'>
                                                    <h1>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                                </div>
                                                <div className='col-span-10'>
                                                    <h1>: {detail?.fullname}</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-2'>
                                                    <h1>{languages.language === 'id' ? "Status" : "Status"}</h1>
                                                </div>
                                                <div className='col-span-10 flex'>
                                                    <span>: &nbsp;</span>
                                                    {detail?.status?.length > 0 ?
                                                        detail?.status.map((val, i) => (
                                                            <>
                                                                {i === 1 ?
                                                                    <h1 className='text-dark-5 text-[11px] font-[500]'> & </h1>
                                                                    : null
                                                                }
                                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{val}</h1>

                                                            </>
                                                        ))
                                                        : "-"
                                                    }
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-2'>
                                                    <h1>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                                                </div>
                                                <div className='col-span-10'>
                                                    <h1>: {detail?.time_checkin && detail?.time_checkout ? duration(detail?.time_checkin, detail?.time_checkout) : '-'}</h1>
                                                </div>
                                            </div>
                                            {/* <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Work Shift</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: -</h1>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-[84px]'>
                                    <div>
                                        <div className='flex text-[14px] text-dark-4 font-semibold gap-[43px] mb-[11px]'>
                                            <h1>{languages.language === 'id' ? "Presensi Masuk" : "Check in"}</h1>
                                            <h2>{moment(detail?.time_checkin).format('HH:mm')}</h2>
                                        </div>
                                        <div className='flex gap-[23px]'>
                                            <div>
                                                <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>Photo</h1>
                                                <div className='w-[77px] h-[76px] bg-dark-9 rounded-[10px] flex items-center justify-center'>
                                                    <img src={detail?.photoClockIn ? detail?.photoClockIn : LogoH} alt='Foto Presensi' />
                                                </div>
                                            </div>
                                            <div>
                                                <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 border'>
                                                    {detail?.latitude_checkin && detail?.longtitude_checkin ? (
                                                        <MapContainer
                                                            center={[detail?.latitude_checkin, detail?.longtitude_checkin]}
                                                            zoom={15}
                                                            style={{ height: '100%', width: '100%' }}
                                                        >
                                                            <TileLayer
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                            />
                                                            <Marker icon={customIcon} position={[detail?.latitude_checkin, detail?.longtitude_checkin]}>

                                                            </Marker>
                                                        </MapContainer>
                                                    ) : (
                                                        <h1 className='text-center text-[10px]'>Lokasi Tidak Ditemukan</h1>
                                                    )}
                                                </div>

                                                {/* <div className='flex gap-[9px]'>
                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                        {locationNameCheckin ? locationNameCheckin : 'Lokasi Tidak Ditemukan'}
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex text-[14px] text-dark-4 font-semibold gap-[43px] mb-[11px]'>
                                            <h1>{languages.language === 'id' ? "Presensi Keluar" : "Check Out"}</h1>
                                            <h2>{detail?.time_checkout ? moment(detail?.time_checkout).format('HH:mm') : '-'}</h2>
                                        </div>
                                        <div className='flex gap-[23px]'>
                                            <div>
                                                <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>Photo</h1>
                                                <div className='w-[77px] h-[76px] bg-dark-9 rounded-[10px] flex items-center justify-center'>
                                                    <img src={detail?.photoClockOut ? detail?.photoClockOut : LogoH} alt='Foto Presensi' />
                                                </div>
                                            </div>
                                            <div>
                                                <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                    {detail?.latitude_checkout && detail?.longtitude_checkout ? (
                                                        <MapContainer
                                                            center={[detail?.latitude_checkout, detail?.longtitude_checkout]}
                                                            zoom={15}
                                                            style={{ height: '100%', width: '100%' }}
                                                        >
                                                            <TileLayer
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                            />
                                                            <Marker icon={customIcon} position={[detail?.latitude_checkout, detail?.longtitude_checkout]}>

                                                            </Marker>
                                                        </MapContainer>
                                                    ) : (
                                                        <h1 className='text-center text-[10px]'>Lokasi Tidak Ditemukan</h1>
                                                    )}
                                                </div>

                                                {/* <div className='flex gap-[9px]'>
                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                        {locationNameCheckout ? locationNameCheckout : 'Lokasi Tidak Ditemukan'}

                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    }
                />
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Kehadiran" : "Attendance"} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px] text-dark-3 font-semibold'>{languages.language === 'id' ? "Catatan Pegawai" : "Employee Records"}</h1>
                            <h2 className='text-dark-5 text-[10px]'>{languages.language === 'id' ? "Daftar Catatan Kehadiran Pegawai" : "List of  record employee attendance"}</h2>
                        </div>

                        <div className="flex flex-wrap justify-between gap-2 mt-[31px]">
                            <div className='flex flex-wrap justify-between gap-2'>
                                <button onClick={() => setShowFilter(!showFilter)} className='bg-white border w-fit rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiFilter className='text-blue-thick' />
                                    <h1 className='text-blue-thick text-sm font-[500]'>Filter</h1>
                                </button>
                                <button onClick={() => setIsAsc(!isAsc)} className='px-[10px] rounded-md border border-gray-300'>
                                    {isAsc === true ?
                                        <FaSortAlphaDown className='text-blue-thick' />
                                        : <FaSortAlphaUp className='text-blue-thick' />
                                    }
                                </button>
                                <button onClick={exportKehadiran} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <TbFileExport className='text-[#003049]' />
                                    <h1 className='text-[#003049] text-sm font-[500]'>Ekspor</h1>
                                </button>
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                    <input onChange={(e) => setFullname(e.target.value)} placeholder='Cari Nama Pegawai...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='overflow-auto'>
                                <table className='space-y-[10px] text-dark-5 w-full'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Tanggal" : "Date"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Presensi Masuk" : "Check In"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Presensi Keluar" : "Check Out"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Actions"}</h1>
                                        </div>
                                    </div>
                                    {Object.values(data).map((item, idx) => (
                                        <div key={idx}>
                                            <div className={`flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]`}>
                                                <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{item.fullname ?? '-'}</h1>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{item.date ? moment(item.date).format('DD/MM/YYYY') : '-'}</h1>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{item.time_checkin ? moment(item.time_checkin).format('HH:mm') : '-'}</h1>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.time_checkout, item.time_checkin)}</h1>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    {item?.status?.length > 0 ?
                                                        item.status.map((val, i) => (
                                                            <>
                                                                {i === 1 ?
                                                                    <h1 className='text-dark-5 text-[11px] font-[500]'> & </h1>
                                                                    : null
                                                                }
                                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{val}</h1>

                                                            </>
                                                        ))
                                                        : "-"
                                                    }
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{item?.keterangan ?? "-"}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    {/* <button onClick={ () => CheckOutDataAttendanceRecord(item.user.id) } className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                                                Check Out
                                                            </button> */}
                                                    <button onClick={() => { setDetail(item); setShowDetailAttendance(true) }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <FiEye className='text-[#003049]' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                lengthData={totalCount}
                                limitData={limit}
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Attendance


